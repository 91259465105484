import React from 'react';
import "slick-carousel/slick/slick.css";
import { Link } from "gatsby";
import { Container, Row, Col} from 'react-bootstrap';
import YouTube from '@u-wave/react-youtube';
import getVideoId from 'get-video-id';
import { VideoPlayButton } from '../components/icon';
// import ScrollAnimation from 'react-animate-on-scroll';

function Ourvideos(props) {
    const [showVideo, setShowVideo] = React.useState(false);
    const [videoindex, setVideoindex] = React.useState(false);
    const [,setPlay] = React.useState(false);
    const playVideo = (video_index) => {  
          setVideoindex(video_index)   
          setShowVideo(true)
    }
    const trackerVideo = (event) => {    
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'Video Play Btn',
          'formType': event,
          'formId': 'Video Play',
          'formName': 'Video',
          'formLabel': 'Video'
        });
    }
    //console.log("props ==>", props)
    return (
        <section className="reid-team 1">
        {/* <ScrollAnimation offset={100} animateIn="animate__fadeInUp" animateOnce={true} > */}
        <Container>
        <Row>
        {props && props.data && props.data.edges && props.data.edges.map(({node},index) => {
        var videoid = getVideoId(node.Embed_Video_URL);
        let processedImages = JSON.stringify({});
            if (node?.imagetransforms?.Video_Tile_Transforms) {
                processedImages = node.imagetransforms.Video_Tile_Transforms;
                //console.log('videoprocessedimages',processedImages)
            }
            const image_url = node.Video_Tile && node.Video_Tile.internal.description ? node.Video_Tile.internal.description.replace("File ","").replace('"','').replace('"','') : '';  
            return<>
                <Col md={6} className="col-xl-4">
                <div className="team-wrap text-center">
                    <figure className={`team-img team_img_${index}`}>
                    <Link to={`${node.URL}/ `}>
                    <div className="bwimg">
                        <img src={image_url} alt={`${node.Title} - Orlando Reid`}/>
                    </div>
                     </Link>
                    {node.Embed_Video_URL &&
                        <button className="btn-play"
                            onClick = { (e) => {playVideo(index, videoid.id,e)}}>
                            <VideoPlayButton />
                        </button>
                    }
                    {showVideo && videoindex== index &&
                        <YouTube
                        video={videoid.id}
                        autoplay
                        onEnd={() => {setPlay(false);setShowVideo(false)}}
                        modestBranding={1}
                        onPlaying={trackerVideo(node.Title)}
                        />
                    }
                    </figure>
                    {/* <div className="team-info">
                    <Link to={`${node.URL}/ `}>
                        <h3>{node.Title}</h3>
                    </Link>

                    </div> */}
                </div>
                </Col>
            </>
          })}
        </Row>
        </Container>
        {/* </ScrollAnimation> */}
      </section>

    )

}

export default Ourvideos;
