import React, { useState } from 'react';
import { Row, Container } from 'react-bootstrap';
import LocRatingMap from '../map/loc-rating-map';
import { MapMarkerInfoBox } from '../map/map-marker';


/**
 * Components
 */

const OfficeLandingMap = (props) => {
    const [] = useState('')
    // Get Map Details
    let mapItems = [];
    {props.offices && props.offices.edges.map(({node}, key) => {

        if(props.title == 'London Offices' && node.Select_Office_Location !="Manchester" && node.Select_Office_Location !="Riyadh") {
            let mapItem = {};
            mapItem['lat']  = node.Latitude;
            mapItem['lng']  = node.Longitude;
            const mapMarkerImageShowFlag = 1;
            let mapItemHtml = MapMarkerInfoBox(node.Title, mapMarkerImageShowFlag);
            console.log( mapItemHtml );
            mapItem['html'] = mapItemHtml;
            mapItems.push( mapItem );
        }

        if(props.title == 'Manchester Office' && node.Select_Office_Location =="Manchester") {
            let mapItem = {};
            mapItem['lat']  = node.Latitude;
            mapItem['lng']  = node.Longitude;
            const mapMarkerImageShowFlag = 1;
            let mapItemHtml = MapMarkerInfoBox(node.Title, mapMarkerImageShowFlag);
            console.log( mapItemHtml );
            mapItem['html'] = mapItemHtml;
            mapItems.push( mapItem );
        }
        console.log("Data 3");
    
    })};
    return (
        <div className="static-wave property-map office-landing-map" id="property-map">
        <div className="wave__video">
            <LocRatingMap data={mapItems} />
        </div>
        </div>
    )
}

export default OfficeLandingMap
