import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import ImageTransform from "../common/ggfx-client/module/components/image-transform";
import "animate.css/animate.css";

import { Link } from "gatsby";
const ImageSlider = (props) => {
    useEffect(()=>{

		function isInViewport(el) {
		    let top = el.offsetTop;
  			let left = el.offsetLeft;
  			let width = el.offsetWidth;
  			let height = el.offsetHeight;

			  while(el.offsetParent) {
			    el = el.offsetParent;
			    top += el.offsetTop;
			    left += el.offsetLeft;
			  }

			  return (
			    top < (window.pageYOffset + window.innerHeight) &&
			    left < (window.pageXOffset + window.innerWidth) &&
			    (top + height) > window.pageYOffset &&
			    (left + width) > window.pageXOffset
			  );

		}

		const box = document.querySelector('.similar-properties .properties');
		document.addEventListener('scroll', handleScroll, {
		    passive: true
		});

		function handleScroll () {
		
  			if(isInViewport(box)) {
  		    
          /** Here one can write animate.css class for animation **/

  				box.classList.add('animate__fadeInUp','animate__slower');  

  			} else {
  				
          box.classList.remove('animate__fadeInUp','animate__slower');
  		
  			}

		}

		return () =>{
			document.addEventListener('scroll', handleScroll);
		}

	},[])

    const settings = {
        dots: false,
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        arrows: false,
        responsive: [
            {
                breakpoint: 1800,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    centerPadding: '192px',
                }
            },
            {
                breakpoint: 1293,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    centerPadding: '140px',
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    centerPadding: '163px',
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: false,
                    centerPadding: '0px',
                    dots: true,
                }
            }
        ]
    };
    return (
        
        <div className="property-slider">
            <Slider {...settings}>
            {props.data.map((item) => {
            let processedImages = JSON.stringify({});
                if (item?.imagetransforms?.images_Transforms) {
                processedImages = item.imagetransforms.images_Transforms;
            }
            var checkptype = item.search_type ==="sales"?'/property-for-sale/' :'/property-to-rent/'
                return <div className="property-block text-center">
                    <Link to={`${checkptype}${item.slug}-${item.id}/`}>
                        <figure className="property-img">
                            <ImageTransform imagesources={item.images[0].url} renderer="srcSet" imagename="property.images.similarpropertytile"attr={{ alt: item.display_address+ ' - Orlando Reid' }}
					imagetransformresult={processedImages} id={item.id} testparam={true} />
                        </figure>
                        <div className="property-title">
                            <h3>{item.display_address}</h3>
                            <span className="price-info">{'£' + item.price.toLocaleString()}</span>
                            <span className="room-info">{item.title}</span>
                        </div>
                    </Link>
                </div>
            })} 
            

            </Slider >
        </div>


    )

}

export default ImageSlider;
