import React, {  } from "react";
import { Container, Col, Row, Form } from 'react-bootstrap';
import get from 'lodash/get'
import Breadcrumbs from "../components/breadcrumbs";
import { useQuery } from '@apollo/client';
import gql from "graphql-tag"
import ReactMarkdown from "react-markdown/with-html"
import Header2 from "../components/Header2/header2";
import Footer from "../components/Footer/footer";
import ContactSection from '../components/SectionContact/section-contact'
import SEO from "../components/Seo/seo";
import CareersForm from "../components/forms/career";
import { Logo } from '../components/icon';

import $ from 'jquery'
const GET_ALL_OPENINGS =  gql`
query GetOpenings ($URL: String!){  
  careers (where:{URL: $URL}){
    Meta_Description
    Meta_Title
    Description
    Location
    Job_Opening
    Salary_Range
    URL
  }
}
`;
function gotohash(event) {
  var thishash = event;
  $('html, body').animate({
      scrollTop: $(thishash).offset().top-80
  }, 1000);
}


function TeamDetail(props) {
  const GlobalConfig = props.GlobalConfig
  const Page = get(this, 'props.data.GetOpenings')
  const { loading:jobsloading, error:jobserror, data:alljobs } = useQuery(GET_ALL_OPENINGS, {
    variables: { URL: props.detailpage }
  });
    if (jobsloading) return (
      <div className="static-details gql-loading-screen">
          <Container>
          <Logo />
          <div className="gql-loading">Loading ...</div>
          </Container>
      </div>);

    return (
      <>
      <div className="wrapper">
      <div className="content">
      <Header2 />

      <section className="static-content job-details">
        <Container>
          {/* static block1 start */}
          <Row>
              {alljobs.careers.map((job, index) => {  
          var metadesc =  "Looking for "+job.Meta_Title+" job? send your CV today to Orlando Reid the leading estate and letting agents and get the right job."
            return <> 
            <SEO title={`${job.Meta_Title} jobs`} description={metadesc} />

            <Breadcrumbs alias='careers' detailname={job.Job_Opening} />

            <div className="breadcrumbs-title">{job.Job_Opening}</div>

            <Col xl={8}>

              {/* static text start */}
              <div className="static-text">
                <div className="job-top">
                  <Row>
                    <Col lg={6} className="job-text"><strong>Location :</strong><br /><br /> {job.Location}</Col>
                    <Col lg={6} className="job-text"><strong>Salary :</strong><br /><br /> {job.Salary_Range}</Col>
                  </Row>
                </div>

                <div className="long-text-wrap">
                  <ReactMarkdown source={job.Description} escapeHtml={false}/>
                </div>
              </div>
              {/* static text end */}
    <Form className="apply-job" id="apply-section">
        <Container>
          <Row>
            <Col lg={11}>
              <h2>
                Apply for this Job
              </h2>
              <p>
              If you’re interested in this position please fill in the form.
            </p>
              <CareersForm jobtitle={job.Job_Opening} />
              
            </Col>
          </Row>
        </Container>
      </Form>
            </Col>
            </>
          })}

            <Col xl={4} className="d-none d-xl-block">
              <div className="newsletter-wrap jobapply-wrap">
                <h2>Interested?</h2>
                <p>You will be joining an outstanding, driven and supportive team, with high expectations, high rewards and the opportunity to carve a real career.</p>                
                <div className="btn-wrap">
                  <button onClick={() => gotohash('#apply-section')}  className="btn btn-success" type="submit">
                  Apply for this job
                  </button>
                </div>
              </div>
            </Col>
          </Row>
          {/* static block1 end */}
        </Container>
      </section>
      <ContactSection getInTouch = {GlobalConfig}  />
      </div>
      <Footer footerDetails = {GlobalConfig} />
      </div>
      </>
      )
}
export default TeamDetail

