import React from "react"
import { useMatch } from "@reach/router"
import JobDetail from './job-details';
import CommonTemplate from './common-template';
import get from 'lodash/get'
import {useStaticQuery, graphql } from 'gatsby'
import PropertyDetails from './property-details';
import NewHomesPropertyDetails from './newhomes-details';
import { navigate }  from "gatsby"
import { Link } from "gatsby"


export default ({data}) => {
  
  const jobdetail = useMatch("/about-us/careers/:item/")
  const salematch = useMatch('/property-for-sale/:slug');
  const salematchlisting = useMatch('/property-for-sale/');
  const rentmatchlisting = useMatch('/property-to-rent/');
  const newhomesalematchlisting = useMatch('/new-home-for-sale/');
  const newhomerentmatchlisting = useMatch('/new-home-to-rent/');

  const newdevelopmentsalematchlisting = useMatch('/new-development-for-sale/');
  const newdevelopmentrentmatchlisting = useMatch('/new-development-to-rent/');

  const helptobuysalematchlisting = useMatch('/help-to-buy-properties-for-sale/');

  const rentmatch = useMatch('/property-to-rent/:slug');
  const newhomesalematch = useMatch('/new-home-for-sale/:slug');
  const newhomerentmatch = useMatch('/new-home-to-rent/:slug');
  const newdevelopmentsalematch = useMatch('/new-development-for-sale/:slug');
  const newdevelopmentrentmatch = useMatch('/new-development-to-rent/:slug');
  const helptobuysalematch = useMatch('/help-to-buy-properties-for-sale/:slug');
  const Page = data.strapiArticles
  const Modules = data.strapiArticles.Modules
  const GlobalConfig = data.strapiGlobalConfig
  const News = data.allStrapiNewsEvents
  const AreaguidesList = data.allStrapiAreaguides
  const OfficesList = data.allStrapiOffices
  const OurvideosList = data.allStrapiOurVideos
  if (typeof window !== "undefined") {

  if(salematchlisting !=null){
    navigate('/property/for-sale/in-london-and-manchester/')
  }
  if(rentmatchlisting !=null){
    navigate('/property/to-rent/in-london-and-manchester/')
  }
  if(newhomesalematchlisting !=null){
    navigate('/property/new-homes/for-sale/in-london/')
  }
  if(newhomerentmatchlisting !=null){
    navigate('/property/new-homes/to-rent/in-london/')
  }
  if(newdevelopmentsalematchlisting !=null){
    navigate('/property/new-developments/for-sale/in-london/')
  }
  if(newdevelopmentrentmatchlisting !=null){
    navigate('/property/new-developments/to-rent/in-london/')
  }

  if(helptobuysalematchlisting !=null){
    navigate('/property/help-to-buy/in-london/')
  }
}
  if(salematch !=null) {
    var propertyURL = salematch.slug
    var propID = propertyURL.split('-').pop()
    return(
    <PropertyDetails resultspage="salespage" Modules={Modules} PropertyID={propID} GlobalConfig={GlobalConfig}/>
    )
  }

  if(rentmatch !=null) {
    var propertyURL = rentmatch.slug
    var propID = propertyURL.split('-').pop()
    return (
    <PropertyDetails resultspage="rentpage" Modules={Modules} PropertyID={propID} GlobalConfig={GlobalConfig}/>
    )
  }
  if(newdevelopmentsalematch !=null) {
    var propertyURL = newdevelopmentsalematch.slug
    var propID = propertyURL.split('-').pop()
    return(
      <NewHomesPropertyDetails resultspage="newsalespage" Modules={Modules} PropertyID={propID} GlobalConfig={GlobalConfig}/>
      )
  }

  if(newdevelopmentrentmatch !=null) {
    var propertyURL = newdevelopmentrentmatch.slug
    var propID = propertyURL.split('-').pop()
    return (
      <NewHomesPropertyDetails resultspage="newrentpage" Modules={Modules} PropertyID={propID} GlobalConfig={GlobalConfig}/>
      )
  }

  if(newhomesalematch !=null) {
    var propertyURL = newhomesalematch.slug
    var propID = propertyURL.split('-').pop()
    return(
      <PropertyDetails resultspage="newsalespage" Modules={Modules} PropertyID={propID} GlobalConfig={GlobalConfig}/>
      )
  }

  if(newhomerentmatch !=null) {
    var propertyURL = newhomerentmatch.slug
    var propID = propertyURL.split('-').pop()
    return (
      <PropertyDetails resultspage="newrentpage" Modules={Modules} PropertyID={propID} GlobalConfig={GlobalConfig}/>
      )
  }
  if(helptobuysalematch !=null) {
    var propertyURL = helptobuysalematch.slug
    var propID = propertyURL.split('-').pop()
    return(
    <PropertyDetails resultspage="helppage" Modules={Modules} PropertyID={propID} GlobalConfig={GlobalConfig}/>
    )
  }
  return jobdetail ? (
    <JobDetail detailpage={jobdetail.item} GlobalConfig={GlobalConfig}/>
  ) : (
    <CommonTemplate Page={Page} Modules={Modules} GlobalConfig={GlobalConfig} News ={News} AreaguidesList={AreaguidesList} OfficesList={OfficesList} OurvideosList={OurvideosList} />
  )

}

export const pageQuery = graphql`
  query DefaultQuery($id: String!) {
        strapiArticles(strapiId: {eq: $id}) {
          Meta_Description
          Meta_Title
          Alias
          id
          strapiId
          Pagename
          Select_Template
          Page_CSS_Class
          Custom_CSS_Class
          Select_Popular_Search
          Sidebar_Newsletter
          Sidebar_News_Content
          imagetransforms{
            Tile_Image_Transforms
            Banner_Image_Transforms
          }
          Modules {
            Valuation_Title
            Valuation_Description
            Valuation_Form_BG {
              childImageSharp {
                fluid(quality: 90, maxWidth: 718) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
              internal {
                description
              }
            }
            Upload_Guide {
              publicURL
                internal {
                  description
                }
            } 
            Valuation_CTA_Boxes {
              Valuation_Box_Content
              Valuation_Box_Label
              Valuation_Box_Title
              Valuation_CTA_URL
              Valuation_CTA_Link {
                URL
              }
            }
            Custom_Button_CTA_Text
            Custom_Button_CTA_View
            Custom_CTA_Review_URL
            Select_Google_Review {
              URL
            }
            Show_Google_Review_Office
            Show_Google_Review_Title
            Show_Review_Office_Id
            Banner_Title
            Brochure {
              publicURL
            }
            Banner_CTA_1_Label
            Banner_CTA_1_URL {
              URL
              Label
            }
            Banner_CTA_2_Label
            Banner_CTA_2_URL {
              URL
              Label
            }
            Banner_1_Hash
            Banner_2_Hash
            Show_Search
            Show_Review
            Show_Banner_Inline_Search
            Banner_Content   
            Banner_Image {
                internal {
                    description
                }
            }
            Awards_intro
            Award_List {
              Awards_Main_Title
              Award_Sub_Title
              Awards_Content
            }
            Awards_Sidebar_Image_Desktop {
              childImageSharp {
                fluid(quality: 100, maxWidth: 420) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            Awards_Sidebar_Image_Ipad {
              childImageSharp {
                fluid(quality: 100, maxWidth: 768) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            Awards_Sidebar_Image_Mobile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 375) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            Awards_Top_Image_Desktop {
              childImageSharp {
                fluid(quality: 100, maxWidth: 420) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            Awards_Top_Image_Ipad {
              childImageSharp {
                fluid(quality: 100, maxWidth: 768) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            Awards_Top_Image_Mobile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 375) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            Awards_Bottom_Image_Desktop {
              childImageSharp {
                fluid(quality: 100, maxWidth: 420) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            Awards_Bottom_Image_Ipad {
              childImageSharp {
                fluid(quality: 100, maxWidth: 768) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            Awards_Bottom_Image_Mobile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 375) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            Static_Banner_Image {
                childImageSharp {
                fluid(quality: 90, maxWidth: 2048) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            Static_Banner_Title  
            Landing_Intro_CTA_Label_1
            Landing_Intro_CTA_Label_2
            Landing_Intro_CTA_URL_1 {
              URL
              Label
            }
            Landing_Intro_CTA_URL_2 {
              URL
              Label
            }
            Embed_Video_URL
            Upload_Video {
              internal {
                description
              }
            }
            Tiles_Big {
              Tile_CTA_Label
              Tile_CTA_Link {
                URL
                Label
              }
              Tile_Block_Image {
                childImageSharp {
                  fixed(width: 604, height: 540) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
              Tile_Name
              Tile_Caption
            }
            Tiles_Big_Property {
              Tile_CTA_Label
              Tile_Name
              Tile_CTA_Link
              Tile_Block_Image {
                childImageSharp {
                  fixed(width: 604, height: 540) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
            Intro_Content
            Intro_Title
            Image_Position
            Tile_Content
            Tile_Image {
                internal {
                    description
                }
            }
            Tile_Title
            Tile_Embed_Video_URL
            Show_Get_Started_Form
            Show_Property_Report
            CTA_Label
            CTA_Dropdown {
              Dropdown_Label
              Dropdown_Value
            }
            CTA_Url {
              URL
              Label
            }
            Custom_CTA_URL
            Statistics_Title
            Statistics_Description
            Stats {
              Prefix
              Caption
              Value
              Value_Type
              Decimal_Value
            }
            Content_Between_Module
            Select_Module
            Select_Form
            Form_Intro
            Form_Banner {
              publicURL
              childImageSharp {
                fluid(quality: 90, maxWidth: 718) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            Select_Listing
            Tiles {
              Title
              Custom_Link
              Tile_Link {
                URL
                Label
              }
              Image {
                childImageSharp {
                  fixed(width: 384, height: 200) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
            Select_Team_Members {
              id
              Name
              URL
              Designation
              Phone
              Embed_Video_URL
              Email
              Staff_Image {
                publicURL
                internal {
                  description
                }
              }
              imagetransforms {
                Staff_Image_Transforms
              }
            }
            ManchesterTitle : Title
            ManchesterTeam : teams {
                 Designation
                 Email
                 Name
                Phone
                Staff_Image {
                  childImageSharp {
                    fluid(quality: 100, maxHeight:100) {
                    ...GatsbyImageSharpFluid
                    }
                  }
                }
                imagetransforms {
                  Staff_Image_Transforms
                }
              }
          }
          Select_News_Category {
            Name
            id
          }
        } 

        strapiGlobalConfig {
          Get_In_Touch_Content
          Get_In_Touch_Embed_Video_Link
          Footer_Office_Address
          Footer_Newsletter_Title
          Instagram_Link
          Linkedin_Link
          Twitter_Link
          Facebook_Link
          Current_Vacancies_Content
          Join_Our_Newsletter_Sidebar_Content
          Team_Slider_Heading
          Get_In_Touch_Image {
            childImageSharp {
              fluid(quality: 100, maxWidth: 900 ,maxHeight: 600) {
              ...GatsbyImageSharpFluid
              }
            }
          }
          Team_Box_Content
          Team_Box_Video_URL
          Team_Box_Image {
            childImageSharp {
              fluid(quality: 100, maxWidth: 900 ,maxHeight: 600) {
              ...GatsbyImageSharpFluid
              }
            }
          }
          Clients {
            Client
            Client_Image {
              publicURL
            }
            Client_Url
          }
          Footer_Links {
            label
            Menu_item {
              URL
              Secondary_URL
            }
          }
        }
        allStrapiNewsEvents(filter: {id: {ne: "News-events_5fcf647332b45e3f44c85c0f"}}, sort: {order: DESC, fields: id},limit:15) {
          edges {
            node {
              strapiId
              URL
              Select_Page
              Intro_Image {
                internal {
                  description
                }
              }
              Title
              Select_Categories {
                Name
                URL
              }
              News_Date
              id
              imagetransforms {
                Intro_Image_Transforms
              }
            }
          }
        }
        allStrapiAreaguides {
          edges {
            node {
              strapiId
              Title
              URL
              Embed_Video_URL
              Select_Areaguide_Location
              Tile_Image {
                publicURL
                internal {
                  description
                }
              }
              imagetransforms {
                Tile_Image_Transforms
              }
              Intro_Caption
              Featured
            }
          }
        }
        allStrapiOffices (sort: {order: ASC, fields: order}) {
          edges {
            node {
              strapiId
              Title
              URL
              Embed_Video_URL
              Latitude
              Longitude
              Select_Office_Location
              Tile_Image {
                publicURL
                internal {
                  description
                }
              }
              imagetransforms {
                Tile_Image_Transforms
              }
              Office_Address
              Phone_Number
              Show_On_Site
            }
          }
        }
        allStrapiOurVideos(sort: {fields: createdAt, order: DESC}, limit: 15) {
          edges {
            node {
              strapiId
              Title
              URL
              Embed_Video_URL
              Select_Page
              Video_Tile {
                publicURL
                internal {
                  description
                }
              }
              imagetransforms {
                Video_Tile_Transforms
              }
            }
          }
        }

    }
  `
