import React, { useEffect, useState, useRef } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import Header from "../components/Header2/header2";
import ValuationForm from "../components/valuation-form";
import LandingImg from "../images/landing-bg.jpg";
import ReactMarkdown from "react-markdown/with-html";
import { openPopupWidget } from "react-calendly";
import '../scss/master.scss';
import { useLocation } from "@reach/router"
import Breadcrumbs from "../components/breadcrumbs";
import ReactPlayer from "react-player";
import Modal from 'react-bootstrap/Modal'
import { Close, VideoPlayButton, Search,VideoPause,Mute,UnMute } from '../components/icon';
import {
  isIOS,
  isMobileOnly,
  isBrowser,
  isMobile
} from "react-device-detect";
import { Link } from "gatsby";
import { navigate } from "@reach/router"
const LandingPage = (props) => {
  const vidRef = useRef(null);
  const [show, setShow] = useState(false);
  const location = useLocation()
  //console.log('aaaaaaaa',location.search)
  const [playvid, setPlayvideo] = useState(false);
  const playvideo = (ref) => {
    setPlayvideo(true)
  }
  const [showResults, setShowResults] = React.useState(false)
  const [showLanding, setshowLanding] = React.useState(true)
    function onClick() {
    setShowResults(true)
    setshowLanding(false)
    if (typeof window !== "undefined") {
      window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
    }
  }

  const onClickCalendly = (url) => openPopupWidget({ url });

  useEffect(() => {
    if(location.search == '?type=inperson') {
      setshowLanding(false)
      setShowResults(true)
      navigate("/property-valuation/free-property-valuation-in-clapham-battersea-and-manchester/");
    }
    if(showLanding == true) {
    let scrollPos = 0;
    window.addEventListener('scroll', handleScroll, true);
    function handleScroll() {
      let headerElement = document.getElementsByClassName("header")[0];
      if ((document.body.getBoundingClientRect()).top === 0) {
        headerElement.classList.remove("header-fixed")
      
      } else if ((document.body.getBoundingClientRect()).top < 0) {
        headerElement.classList.add("header-fixed")
       
      }
      scrollPos = (document.body.getBoundingClientRect()).top;
    }
    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  }

  }, [])

 
  return (<div className="wrapper">
    {/* header start */}
    {showResults &&
    <Header />
    }
    {/* header end */}
    {/* content start */}
    <div className="content">
      {/* landing content start */}
    { showResults ? <ValuationForm BgImg={props.BgImg} />: null }

    {showLanding &&

      <div className="landing-content valuation-landing">
        <div className="banner-img">
          <img src={LandingImg} alt="img" />
          {playvid && show &&
          <Modal dialogClassName="modal-full-video" show={show} fullscreen={true} onHide={() => setShow(false)}>
          <div className="full-modal-close">
            <a href="javascript:;" onClick={()=>{setShow(false);}}><Close/></a>
          </div>
          <ReactPlayer autoplay ref={vidRef} url= "https://ggfx-orlandoreid.s3.eu-west-2.amazonaws.com/i.prod/ORG_Valuation_ad_without_intro_8mb_8b9b5f179b.mp4" controls = {isIOS && isMobileOnly?true:false} autoplay={true} muted={false} playsinline={true} playing={true} onEnded={() =>{setPlayvideo(false)}}  width='100%'height='100%' />
          </Modal>
          }
        </div>
        <Container>
          <div className="landing-head text-center">
            <Breadcrumbs/>
            <h1>{props.Title}</h1>
            <a href="javascript:;" onClick={()=>{setShow(true);playvideo();}}>  <VideoPlayButton /></a>
            <ReactMarkdown source={props.Description} escapeHtml={false}/>
          </div>
          {/* property-wrap start */}
          {props.CTABoxes !=null &&
          <Row className="landing-items">
            {props.CTABoxes.map((box) => {
              return<>
              <Col xl="4">
              <div className="landing-block valuation-block text-center">
                <h3>{box.Valuation_Box_Title}</h3>                
                <ReactMarkdown source={box.Valuation_Box_Content} escapeHtml={false}/>
                {/* {box.Valuation_Box_Title == "In Person Valuation" &&
                <Link className="btn btn-default triggervalform" to={box?.Valuation_CTA_Link?.URL} >{box.Valuation_Box_Label}</Link>
                }
                {box.Valuation_Box_Title == "Instruct Us Now" && 
                  <Link className="btn btn-default" to={box?.Valuation_CTA_URL} >{box.Valuation_Box_Label}</Link>  
                }
                {box.Valuation_Box_Title != "In Person Valuation" && box.Valuation_Box_Title !="Virtual Valuation" && box.Valuation_Box_Title !== "Instruct Us Now" && 
                <a className="btn btn-default" target="_blank" href={box.Valuation_CTA_URL}>{box.Valuation_Box_Label}</a>
                } */}
                {box.Valuation_Box_Title === "Online Valuation" ?
                  <a className="btn btn-default" target="_blank" href={box.Valuation_CTA_URL}>{box.Valuation_Box_Label}</a> :
                  <Link className="btn btn-default" to={box?.Valuation_CTA_URL ? box?.Valuation_CTA_URL : box?.Valuation_CTA_Link?.URL} >{box.Valuation_Box_Label}</Link>     
                 }

              </div>
            </Col>
            </>
            })}            
          </Row>
          }
          {/* property-wrap end */}
        </Container>
      </div>
    }
      {/* landing content end */}
    </div>
    {/* content end */}
    {/* footer start */}
    {/* footer end */}
  </div>
  )
}

export default LandingPage
