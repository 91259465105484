import React, { useState } from "react"
import { Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import ManchesterAreaguide from "../images/ManchesterAreaguide.jpg"
import LondonAreaguide from "../images/LondonAreaguide.jpeg"
// import ScrollAnimation from "react-animate-on-scroll"

const Manchester = {
  Title: "Explore our Manchester Area Guides",
  subTitle: "Practical living to our Manchester Area Guides",
  content:
    "Our useful guides provide an excellent introduction to all the areas where our offices are located. Whether you are moving home and want to know about schools or local amenities, relocating a business and are seeking communications information or just simply looking for a day out locally, these handy guides are here to help you.",
  CTAtext: "Explore our Manchester Area Guides",
  CTAlink: "/about-us/areas-we-cover/manchester-area-guides/",
  image: ManchesterAreaguide,
}
const London = {
  Title: "Explore our London Area Guides",
  subTitle: "Practical living in beautiful surroundings",
  content:
    "Our useful London area guides provide an excellent introduction to all the areas we cover. Whether you are moving home and want to know about schools or local amenities, relocating a business and are seeking communications information or simply looking for a local day out, our London area guides are here to help you.",
  CTAtext: "Explore our London Area Guides",
  CTAlink: "/about-us/areas-we-cover/london-area-guides/",
  image: LondonAreaguide,
}
function Areaproperty(props) {
  //const showCustom = props.newhomes;
  const url = typeof window !== "undefined" ? window.location.pathname : ""

  const [isLondon, setIsLondon] = useState(
    url.includes("london")
  )
  return (
    <section className="section-explore areaproperty">
      <Container className="market animate__animated ">
        <Row className="align-items-lg-center flex-md-row-reverse">
          <Col  md="5" lg="5" className="col-xl-5 imgsec">
            {/* <ScrollAnimation
              animateIn="animate__fadeInRight"
              animateOnce={true}
            ></ScrollAnimation> */}
            <div className="vedio-card property_areaguide d-flex">
              {isLondon ? (
                <img src={London.image} />
              ) : (
                <img src={Manchester.image} />
              )}
            </div>
          </Col>
          <Col md="7" lg="7" className="col-xl-7 textcont ">
            {/* <ScrollAnimation animateIn="animate__fadeInLeft" animateOnce={true}> */}
              <div className="property-report">
                <span className="sub-title">
                  {isLondon ? London.Title : Manchester.Title}
                </span>
                {/* <h2>{isLondon ? London.subTitle : Manchester.subTitle} </h2> */}
                <p> {isLondon ? London.content : Manchester.content}</p>

                {isLondon ? (
                  <Link className="btn btn-default" to={London.CTAlink}>
                    {London.CTAtext}
                  </Link>
                ) : (
                  <Link className="btn btn-default" to={Manchester.CTAlink}>
                    {Manchester.CTAtext}
                  </Link>
                )}
              </div>
            {/* </ScrollAnimation> */}
          </Col>
        </Row>
      </Container>
    </section>
  )
}
export default Areaproperty
