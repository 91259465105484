import React from "react"
import { useMatch } from "@reach/router"
import AllTeams from './all-teams';
import TeamDetail from '../templates/team-details';

const App = (props) => {
const match = useMatch('/about-us/our-people/');
const detailmatch = useMatch("/about-us/our-people/:item/")
  if(match != null) {
    return (
    <AllTeams/>
    )
  }
  if(detailmatch != null) {
    return (
    <TeamDetail detailpage={detailmatch.item}/>
    )
  }
}
export default App
