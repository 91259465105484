import React, { useState, useEffect } from "react";

import gql from "graphql-tag"
import { useQuery } from '@apollo/client';
import { useStaticQuery, graphql } from "gatsby";
import { Link } from "gatsby"
import { Container, Col, Row } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal'
import MemberForm from "./forms/member-form"
import SalesForceForm from "./forms/salesforce-form"
import ImageTransform from "./common/ggfx-client/module/components/image-transform";
import { Logo } from '../components/icon';
import { useLocation } from "@reach/router"
const CRM_NEG = gql`
query crm_negostaff($NEGID: String!,$URL: String!){
  rep_teams: teams(where:{Neg_ID:$NEGID}) {
    id
    Name
    URL
    Designation
    Phone
    Embed_Video_URL
    Email
    Staff_Image {
      url
    }
    imagetransforms
  }
  jux_teams: teams(where:{Name_contains:$URL}) {
    id
    Name
    URL
    Designation
    Phone
    Embed_Video_URL
    Email
    Staff_Image {
      url
    }
    imagetransforms
  }
}
`;


function TeamDetails(props) {
  const [show, setShow] = useState(false);
  const propLocation = useLocation();
  const curentUrl = process.env.GATSBY_SITE_URL+propLocation.pathname;
  const crm_provider = props.crm_provider;
  // let crm_negotiator_id = props.crm_negotiator_id ? props.crm_negotiator_id : 'WIB';
  // if(crm_provider=="vebra") {
    let crm_negotiator_id = props.crm_negotiator_id ? props.crm_negotiator_id : (props.search_type == "lettings" ? 'Alberts Marques' : 'Matt Hope');
    if(props.officecrmid == 5 && !props.crm_negotiator_id){
      crm_negotiator_id = "WIB"
    }
  // }
  let load_data = "";
  //console.log("crm provider",crm_provider)
  const { loading:crm_team_loading, error:crm_team_erro, data:crm_team_data } = useQuery(CRM_NEG, {
    variables: { URL: crm_negotiator_id, NEGID: crm_negotiator_id }
  });
  if (crm_team_loading) return (
    <div className="static-details gql-loading-screen">
        <Container>
        <Logo />
        <div className="gql-loading">Loading ...</div>
        </Container>
  </div>);
  //console.log("crm data",crm_team_data)
  if(crm_team_data.jux_teams.length > 0) {
    load_data = crm_team_data.jux_teams;
  } else {
    load_data = crm_team_data.rep_teams;
  }
  //console.log("crm_negotiator_id",crm_negotiator_id)
  //console.log("load_data",load_data)

  return (
    <>
		{load_data.map((team, key) => {

      let processedImages = JSON.stringify({});
      if (team?.imagetransforms?.Staff_Image_Transforms) {
            processedImages = team.imagetransforms.Staff_Image_Transforms;
      }
      //const image_url = team.Staff_Image.internal.description ? team.Staff_Image.internal.description.replace("File ","").replace('"','').replace('"','') : '';
			return <>
      <div className="profile-details d-flex align-items-center">
      {team.Staff_Image !=null &&
                <figure className="profile-img">
                  <ImageTransform imagesources={team.Staff_Image && team.Staff_Image.url} renderer="srcSet" imagename="teams.Staff_Image.avartar"attr={{ alt: team.Name+' ' + team.Designation+' - Orlando Reid' }}
					imagetransformresult={processedImages} id={team.id} testparam={true} />
                </figure>
      }

                <div className="profile-desc">
                  <span className="user-name">{team.Name}</span>
                  <span className="designation">{team.Designation}</span>
                  <ul className="d-flex">
                    {team.Phone &&
                    <li>
                      <a href={`tel:${team.Phone}`}>{team.Phone}</a>
                    </li>
                    }
                    <li>
                      <a href="javascript:;" onClick={() => setShow(true)}>Email</a>
                    </li>
                    <Modal
        show={show}
        centered={true}
        onHide={() => setShow(false)}
        dialogClassName="modal-90w modal-form"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            CONTACT - {team.Name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {(props.city.toLowerCase() == "manchester"  || props.city.toLowerCase() == "manchster" || props.propertyurl.includes("manchester") || props.propertyurl.includes("manchster")) ?
           <SalesForceForm className="full-width" propertyName={props.displayAddress} retURL={process.env.GATSBY_SITE_URL+"/thank-you-contact-staff-form"} /> 
           : 
           <MemberForm property_url = {props.propertyurl} to_email_id={team.Email} office="manchester" formName={`Contact Person`} />
         }
      
        </Modal.Body>
      </Modal>

                  </ul>
                </div>
              </div>


			</>
		})}
        </>
  )
}

export default TeamDetails
