import React from 'react';
import { useStaticQuery, graphql } from "gatsby";
import { Col, Container, Row } from 'react-bootstrap';
import LocRatingMap from './map/loc-rating-map';
import { MapMarkerInfoBox } from './map/map-marker';

/**
 * Assets
 */

/**
 * Components
 */

const PropertyLocation = (props) => {
  // Get Map Details
  let mapItems = [];
  let mapItem = {};
  mapItem['lat']  = props.Latitude;        
  mapItem['lng']  = props.Longitude;
  const mapMarkerImageShowFlag = 0;
  let mapItemHtml = MapMarkerInfoBox(props.data.Title + props.data.Address, mapMarkerImageShowFlag);
  mapItem['html'] = mapItemHtml;
  mapItems.push( mapItem );

    const data = useStaticQuery(graphql`
        query {
        video: file(relativePath: { eq: "video2.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 2048) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        }
    `);

    const styles = {
        control: styles => ({ 
            ...styles, 
            backgroundColor: null, 
            border: 0,
            outline: 0,
            boxShadow: 'none',
            color: '#5D9272'
        }),
        option: (styles) => ({
            ...styles
        }),
        placeholder: () => null,
        dropdownIndicator: (styles) => ({
            ...styles,
            color: "#5D9272"
        }),
        indicatorsContainer: (styles) => ({
            ...styles,
            color: '#5D9272',
        }),
        indicatorSeparator: () => null,
        singleValue: styles => ({
            ...styles,
            color: '#5D9272',
        })
    }

   

    return (
        <div className="property-location detail-office-map" data-aos="fade-up">
            <Container>
                <Row>
                    <Col lg="12" id="map" className="order-lg-1 map-holder">
                        <div >
                            <LocRatingMap data={mapItems} />
                        </div>
                    </Col>

                </Row>
            </Container>
        </div>
    )
}

export default PropertyLocation
