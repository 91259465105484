import React, { useState } from "react";
import { Container, Button, Form } from 'react-bootstrap';
import PropTypes from "prop-types";
import Slider from "react-slick";
import { Link } from "gatsby";
import BannerImg1 from "../images/banner-img.jpg";
import BannerImg2 from "../images/banner-img-tab.jpg";
import BannerImg3 from "../images/banner-img-mb.jpg";
import Grate from "../images/google-rate.png";
import PlayButton from './PlayButton/playButton'
import ReactPlayer from "react-player";
import Vimeo from '@u-wave/react-vimeo';
import BookingModal from "./book-view-modal2";
import PropertyGallery from "./property-gallery";
import ImageTransform from "./common/ggfx-client/module/components/image-transform";
import { AreaGuideArrow, BtnPlay, Refresh } from '../components/icon';
import Modal from 'react-bootstrap/Modal'

const Banner = (props) => {
  const [show, setShow] = useState(false);
  const [showvideo, setShowVideo] = useState(false);

  let processedImages = JSON.stringify({});
  if (props?.imagetransforms?.images_Transforms) {
    processedImages = props.imagetransforms.images_Transforms;
  } 
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false, 
    responsive: [ 
        {
            breakpoint: 991,
            settings: { 
                // dots: false,

            }
        } 
    ]
};

  return (
    <section className="banner">

      <div className="banner-img-newhomes">

        <Slider {...settings}>
          {props.propertyimages && props.propertyimages.map((item, key) => { 
            if(item.url){
              return <picture>
              <ImageTransform imagesources={item.url} renderer="srcSet" imagename="property.images.newhomebanner" attr={{ alt: props.proptitle + ' - Orlando Reid' }}
                imagetransformresult={processedImages} id={props.id} testparam={true} />
            </picture>
            }
         
          })}

        </Slider >
        <div className="new-homes-banner-overlay"></div>
        <ul className="action-list d-flex">

          {props.PropVideo != null && props.PropVideo != '' &&
            <li>
              <a href={props.PropVideo} target="_blank">
                <button className="btn-tour btn-detail-play">
                  <BtnPlay />Video
                </button>
              </a>
              <Modal
                show={showvideo}
                size="lg"
                centered={true}
                onHide={() => setShowVideo(false)}
                dialogClassName="modal-90w modal-form modal-tour"
                aria-labelledby="example-custom-modal-styling-title"
              >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                  <iframe width="100%" height="500px" src={props.PropVideo} />
                </Modal.Body>
              </Modal>
            </li>
          }

          {props.videotour != null && props.videotour != "" &&
            <li>
              <a href="javascript:;" onClick={() => setShow(true)}>
                <button className="btn-tour">
                  <Refresh />360
                </button>
              </a>
              <Modal
                show={show}
                size="lg"
                centered={true}
                onHide={() => setShow(false)}
                dialogClassName="modal-90w modal-form modal-tour"
                aria-labelledby="example-custom-modal-styling-title"
              >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                  <iframe width="100%" height="500px" src={props.videotour} />
                </Modal.Body>
              </Modal>
            </li>
          }
          <PropertyGallery propertyimages={props.propertyimages} />
        </ul>


      </div>

      {/* <div className="banner-content text-center">
        <Container>
          <h1>{props.proptitle} in {props.BannerTitle}</h1>
          <Link className="help-link" to="#">From £{props.price.toLocaleString()}</Link>
          <div className="btn-wrap">
            <BookingModal propertyurl={props.propertyurl} officecrmid={props.officecrmid} />
          </div>

        </Container>
      </div> */}

    </section>
  )
}

Banner.propTypes = {
  siteTitle: PropTypes.string,
}

Banner.defaultProps = {
  siteTitle: ``,
}

export default Banner
