import React, { useState, useEffect } from "react";
import {useStaticQuery, graphql } from 'gatsby'
import { Container, Col, Row, Form } from 'react-bootstrap';
import get from 'lodash/get'
import { Link } from "gatsby";
import Breadcrumbs from "../components/breadcrumbs";
import InnerBanner from "../components/inner-banner"
import InnerDetail from "../components/inner-detail"
import OtherNews from "../components/othernewsSection"
import { useQuery } from '@apollo/client';
import gql from "graphql-tag"
import ReactMarkdown from "react-markdown/with-html"
import SEO from "../components/Seo/seo";
import ValuationForm from "../components/forms/valution-form";
import LandingImg from "../images/valuation-bg.png";
import Img from 'gatsby-image';
import { useLocation } from "@reach/router";


function TeamDetail(props) {
  var { pathname } = useLocation()
  const pathArray = ['/property-valuation/free-property-valuation-in-clapham-battersea-and-manchester', '/property-valuation/free-property-valuation-in-clapham-battersea-and-manchester/']
  let isPath = pathArray.includes(pathname);

  return (
  <>
<section className="section-contact team-details">
          <div className="contact-img contact-imgx animate__animated">
          <Breadcrumbs/>
          <Img fluid={isPath ? props.Valuation_Form_BG : props.BgImg} alt={`Property Valuation - Orlando Reid`}/>
          </div>
          <Container>
            <Row className="justify-content-xl-end">
              <Col className="col-xl-6 textContact animate__animated ">
                <ValuationForm Title={props?.Title}/>
              </Col>
            </Row>
          </Container>
  </section>  
  </>
)
}
export default TeamDetail

