import React, { useState, useEffect } from "react";
import '../scss/master.scss';
import Header from "../components/Header/header";
import Footer from "../components/Footer/footer";
import Banner from "../components/Banner/banner";
import { Link } from "gatsby";
import {useStaticQuery, graphql } from 'gatsby'
import get from 'lodash/get'
import { Button, Container, Row, Col, Form } from 'react-bootstrap';
import AboutSection from '../components/SectionAbout/section-about'
import TilesSectionLeft from '../components/section-tiles-left'
import TilesSectionRight from '../components/section-tiles-right'
import LandingTiles from '../components/landing-tiles'
import LandingTilesBig from '../components/landing-tiles-big'
import LandingTilesBigProperty from '../components/landing-tiles-big-property'
import DataSection from '../components/DataSection/datasection'
import MarketSection from '../components/SectionMarket/section-market'
import NewsSection from '../components/NewSection/newsSection'
import SectionArea from '../components/SectionArea/section-area'
import PropertiesSection from '../components/SectionProperties/section-properties'
import PropertiesSectionLettings from '../components/SectionProperties/section-properties-lettings'
import ContactSection from '../components/SectionContact/section-contact'
import TeamModule from '../components/section-team'
import Allstories from '../collections/all-stories'
import FilterNews from '../collections/news-filter'
import InnerBanner from "../components/inner-banner-static"
import InnerDetail from "../components/inner-detail-static"
import StampDutyCalc from "../components/calculators/stampdutycalc/index"
import MortgageCalc from "../components/calculators/mortgagecalc/index"
import Header2 from "../components/Header2/header2";
import LandingIntro from '../components/landing-intro'
import Areaguides from '../components/areaguides-listing'
import ManchesterAreaguides from '../components/manchester-areaguides-listing'
import Offices from '../components/office-listing'
import ManchesterOffices from '../components/manchester-office-listing'
import Teamslider from '../components/team-slider'
import CountUp from 'react-countup';
import TeamListing from '../components/team-listing'
import CurrentVacancies from '../components/current-vacancies'
import SEO from "../components/Seo/seo";
import OfficeLandingMap from "../components/office-landing-map/office-landing-map";
import GoogleReview from '../components/google-reviews'
import ValuationPage from '../components/valuation-page'
import ContactPage from '../components/contact-form-page'
import SiteMap from '../components/SiteMap/sitemap'
import OurVideos from '../components/our-videos'
import SearchHorizontal from '../components/search-horizontal'
import IntroCTAS from '../components/intro-ctas'
import ValuationFormPage from '../components/valuation-form'
import BlogValuation from '../components/blog-page-valuation-form';
import Reviews from "../components/Reviews/Reviews";
class DefaultTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {midcontent: '', arealist:''};
  }

  componentDidMount() {
    let scrollPos = 0;
    window.addEventListener('scroll', handleScroll, true);
    function handleScroll(e) {
      let headerElement = document.getElementsByClassName("header")[0];
      if(headerElement) {
      if ((document.body.getBoundingClientRect()).top === 0) {
        headerElement.classList.remove("header-fixed")
        // headerElement.classList.add("fixed-top")
      } else if ((document.body.getBoundingClientRect()).top < 0) {
        headerElement.classList.add("header-fixed")
        //headerElement.classList.remove("fixed-top")
      }
      scrollPos = (document.body.getBoundingClientRect()).top;
    }
  }
    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  }

 
  render() {
   
    const Page = this.props.Page
    const Modules = this.props.Modules
    const GlobalConfig = this.props.GlobalConfig
    const News = this.props.News
    const AreaguidesList = this.props.AreaguidesList
    const OfficesList = this.props.OfficesList
    const OurvideosList = this.props.OurvideosList
    //const AreaListMidContent = Modules[2].Content_Between_Module && Modules[2].Content_Between_Module
    const AreaListMidContent = Modules.filter((Modules) => Modules.Content_Between_Module != null).map(({Content_Between_Module}) => ({Content_Between_Module}));
    const InvestorGuide = Modules.filter((Modules) => Modules.Upload_Guide != null).map(({Upload_Guide}) => ({Upload_Guide}));
    const Static_Banner_Title = Modules.filter((Modules) => Modules.Static_Banner_Title != null).map(({Static_Banner_Title}) => ({Static_Banner_Title}));
    const ManchesterData = Modules.filter((Modules) => Modules.ManchesterTitle != null).map(({ManchesterTitle, ManchesterTeam}) => ({ManchesterTitle: ManchesterTitle, ManchesterTeam: ManchesterTeam}));
    const TeamHeading = GlobalConfig.Team_Slider_Heading
    const ValBG = Modules.filter((Modules) => Modules.Valuation_Form_BG != null).map(({Valuation_Form_BG}) => ({Valuation_Form_BG}));
    //console.log('aaaaaaaa',InvestorGuide);
    const Area = (() => {
      if (Page.Pagename=="Manchester") {
        return 'Manchester';
      } else {
        return 'London';
      }
    })();
    //console.log("InvestorGuide",InvestorGuide)
    //console.log('testtest', Modules,Page)
    return (
      <>
  <div className={`wrapper ${Page.Custom_CSS_Class}`}>
    {/* header start */}
    {Page.Select_Template !="Landing_Page" &&
      <Header />
    }
    {Page.Select_Template =="Landing_Page" &&
      <Header2 />
    }
    {/* header end */}
    <SEO title={Page.Meta_Title} description={Page.Meta_Description} />

    {/* content start */}
    <div className={`content ${Page.Page_CSS_Class !=null ? Page.Page_CSS_Class : 'static-page'}`}>
    {Modules.map((Modules, i) => {

          return (
            <>
            {Modules.Banner_Image !=null &&
            <Banner InvestorsGuide={InvestorGuide.length > 0 && InvestorGuide } AriticleId = {Page.strapiId} pagetitle ={Page.Pagename} Title={Modules.Banner_Title} Content = {Modules.Banner_Content} CTALabel= {Modules.Banner_CTA_1_Label} CTAUrl= {Modules?.Banner_CTA_1_URL} CTALabel2= {Modules.Banner_CTA_2_Label} CTAUrl2= {Modules.Banner_CTA_2_URL} CTA1Hash={Modules.Banner_1_Hash} CTA2Hash={Modules.Banner_2_Hash} BannerImg={Modules.Banner_Image} VideoURL={Modules.Embed_Video_URL} search={Modules.Show_Search} review={Modules.Show_Review} inlinesearch={Modules.Show_Banner_Inline_Search} HTMLVideo={Modules.Upload_Video}  imagetransforms ={Page?.imagetransforms} appId="articles" brochure={Modules?.Brochure}/>}
            {Modules.Intro_Title !=null && Modules.Intro_Content !=null && Page.Select_Template !="Landing_Page" && Page.Select_Template !="Static_Page" &&
            <AboutSection Title = {Modules.Intro_Title} Content = {Modules.Intro_Content} pageName={Page.Pagename} />
            }
            {Modules.Intro_Title !=null && Modules.Intro_Content !=null && Page.Select_Template =="Landing_Page" && Page.Select_Template !="Static_Page" && Page?.Custom_CSS_Class !== "investors-guide" &&
            <LandingIntro pagetitle ={Page.Pagename} Title = {Modules.Intro_Title} Content = {Modules.Intro_Content} />
            } 
            {Modules.Intro_Title ==null && Modules.Intro_Content !=null && Page.Select_Template !="Static_Page" &&
            <LandingIntro pagetitle ={Page.Pagename} Title = {Modules.Intro_Title} Content = {Modules.Intro_Content} />
            } 
            {Modules.Image_Position == "Left" && Modules.Tile_Image !=null &&
              <TilesSectionLeft Title = {Modules.Tile_Title} AriticleId= {Page.strapiId} Content={Modules.Tile_Content}
              ShowForm = {Modules.Show_Get_Started_Form} Label ={Modules.CTA_Label} TileImg={Modules.Tile_Image} TileVideo = {Modules.Tile_Embed_Video_URL} CTAUrl = {Modules.CTA_Url} CustomUrl={Modules.Custom_CTA_URL} Show_Property_Report={Modules?.Show_Property_Report} imagetransforms ={Page?.imagetransforms} appId="articles"/>
            }
            {Modules.Stats !=null &&
            <DataSection StatTitle = {Modules.Statistics_Title} StatDesc = {Modules.Statistics_Description} data={Modules.Stats} />
            }
            {Modules.Show_Google_Review_Office != null &&
                    <div id="reviews">
                      <Reviews ReviewTitle={Modules.Show_Google_Review_Title} CTAText={Modules.Custom_Button_CTA_Text} CTAView={Modules.Custom_Button_CTA_View} ReviewURL={Modules.Custom_CTA_Review_URL} GoogleReview={Modules.Select_Google_Review.URL} ReviewId={Modules.Show_Review_Office_Id} />
                    </div>
                  }
            {Modules.Image_Position == "Right" && Modules.Tile_Image !=null &&
              <TilesSectionRight Title = {Modules.Tile_Title} AriticleId = {Page.strapiId}  Content={Modules.Tile_Content}
              ShowForm = {Modules.Show_Get_Started_Form} Label ={Modules.CTA_Label} TileImg={Modules.Tile_Image} TileVideo = {Modules.Tile_Embed_Video_URL} CTAdrodown ={Modules.CTA_Dropdown} CTAUrl = {Modules.CTA_Url} Custom_CTA_URL={Modules.Custom_CTA_URL} Show_Property_Report={Modules?.Show_Property_Report}  imagetransforms ={Page?.imagetransforms} appId="articles"/>
            }
            {Modules.Select_Module == "News_Insights_Slider" &&
              <NewsSection data={News} videos={OurvideosList} pagetitle={Page.Pagename} />
            }
            {Modules.Select_Module == "Team_Listings" &&
              <TeamListing  VideoURL={Modules.Embed_Video_URL} />
            }
            {Modules.Select_Module == "Areaguides_Listings" && 
              <Areaguides data={AreaguidesList} midcontent={AreaListMidContent.length > 0 && AreaListMidContent[0].Content_Between_Module}  />
            }
            {Modules.Select_Module == "Manchester_Areaguides_Listings" && 
              <ManchesterAreaguides data={AreaguidesList} midcontent={AreaListMidContent.length > 0 && AreaListMidContent[0].Content_Between_Module}  />
            }
   

            
            {Modules.Select_Module == "Google_Reviews" && 
              <GoogleReview  />
            }
            
            {Modules.Landing_Intro_CTA_Label_1 !=null &&            
            <IntroCTAS CTA1= {Modules.Landing_Intro_CTA_Label_1} CTA2= {Modules.Landing_Intro_CTA_Label_2}
            CTA1Url = {Modules.Landing_Intro_CTA_URL_1} CTA2Url = {Modules.Landing_Intro_CTA_URL_2}
            /> 
            }
            
            {Modules.Select_Module == "Office_Listings" && 
              <Offices data={OfficesList}  />
            }
            {Modules.Select_Module == "Manchester_Office_Listings" && 
             <ManchesterOffices data={OfficesList}  />
            }
            {Modules.Select_Team_Members !=null && 
              <Teamslider data={Modules.Select_Team_Members} TeamHeading={TeamHeading} />
            }
            {Modules.Select_Listing =="All_Stories" &&
              <Allstories data={News} videodata ={OurvideosList}  />
            }
            {Modules.Select_Module == "Current_Vacancies" &&
              <CurrentVacancies Heading={GlobalConfig.Current_Vacancies_Content}/>
            }
            {Modules.Select_Module == 'Office_Landing_Map' &&
            <OfficeLandingMap offices= {OfficesList} title={Page.Pagename}/>
            }
            {Modules.Valuation_Title !=null &&
            <ValuationPage Title={Modules.Valuation_Title} Description={Modules.Valuation_Description} CTABoxes={Modules.Valuation_CTA_Boxes} BgImg={Modules.Valuation_Form_BG.childImageSharp.fluid}/>
            }
            {Modules.Select_Module == 'Our_Videos' &&
              <OurVideos data ={OurvideosList} />
            }

            {Modules.Select_Module == "Featured_Properties" &&
              <PropertiesSection area={Area}/>
            }
            {Modules.Select_Module == "Featured_Properties_Lettings" &&
              <PropertiesSectionLettings area={Area}/>
            }
            {Modules.Tiles !=null &&
              <LandingTiles data={Modules.Tiles}/>
            }
            {Modules.Tiles_Big !=null &&
              <LandingTilesBig data={Modules.Tiles_Big}/>
            }
            {Modules.Tiles_Big_Property !=null &&
              <LandingTilesBigProperty data={Modules.Tiles_Big_Property}/>
            }
            {Page.Select_Template == "Static_Page" && Modules.Static_Banner_Title !=null && Modules.Static_Banner_Image &&
              <InnerBanner Title={Modules.Static_Banner_Title}  BannerImg={Modules.Static_Banner_Image.childImageSharp.fluid} />

            }
            {Page.Select_Template == "Static_Page" && Modules.Intro_Content !=null && 
              <InnerDetail InvestorsGuide={InvestorGuide.length > 0 && InvestorGuide }Content={Modules.Intro_Content} Sidebar={Page.Sidebar_Newsletter} CustomClass={Page.Custom_CSS_Class} Sidebar_News_Content={Page?.Sidebar_News_Content}
              Static_Banner_Title = {Static_Banner_Title.length > 0 && Static_Banner_Title}
              ManchesterData={ManchesterData}
              />
            }
            {Page.Select_Template == "Landing_Page" && Modules.Intro_Content !=null && Page?.Custom_CSS_Class === "investors-guide" &&
              <InnerDetail InvestorsGuide={InvestorGuide.length > 0 && InvestorGuide }Content={Modules.Intro_Content} Sidebar={Page.Sidebar_Newsletter} CustomClass={Page.Custom_CSS_Class} Sidebar_News_Content={Page?.Sidebar_News_Content}
              Static_Banner_Title = {Static_Banner_Title.length > 0 && Static_Banner_Title}
              ManchesterData={ManchesterData} Title = {Modules.Intro_Title}
              />
            }
             {Page.Select_Template == "Static_Page" && Modules.Awards_intro !=null && 
              <InnerDetail AwardsContent={Modules.Awards_intro} AwardList={Modules.Award_List} AwardimageDesktop={Modules.Awards_Sidebar_Image_Desktop} AwardimageIpad={Modules.Awards_Sidebar_Image_Ipad} AwardimageMobile={Modules.Awards_Sidebar_Image_Mobile}  AwardtopimageDesktop={Modules.Awards_Top_Image_Desktop} AwardtopimageIpad={Modules.Awards_Top_Image_Ipad} AwardtopimageMobile={Modules.Awards_Top_Image_Mobile} AwardbottomimageDesktop={Modules.Awards_Bottom_Image_Desktop} AwardbottomimageIpad={Modules.Awards_Bottom_Image_Ipad} AwardbottomimageMobile={Modules.Awards_Bottom_Image_Mobile} Sidebar={Page.Sidebar_Newsletter} CustomClass={Page.Custom_CSS_Class
              }/>
            }

            {Modules.Select_Module == "Stamp_Duty_Calculator" &&
              <StampDutyCalc />
            }
            {Modules.Select_Module == "Mortgage_Calculator" &&
              <MortgageCalc />
            }
            {Modules.Select_Module == "GetIn_Touch" &&
              <ContactSection getInTouch = {GlobalConfig}  />
            }
            {Modules.Select_Form == "Contact_Form" && Modules.Form_Banner !=null &&
              <ContactPage Title={Page.Pagename} FormBG = {Modules.Form_Banner.childImageSharp.fluid} FormIntro={Modules.Form_Intro} />
            }
            {Modules.Select_Form == "Property_Report_Form" ||  Modules.Select_Form == "Nottingham_Investment_Enquiry" && Modules.Form_Banner !=null &&
              <ContactPage Title={Page.Pagename} FormBG = {Modules.Form_Banner.childImageSharp.fluid} FormIntro={Modules.Form_Intro} formname={Modules.Select_Form} />
            }
            {Modules.Select_Module == "Team_Module" &&
              <TeamModule getInTouch = {GlobalConfig}  />
            }
            {Modules.Select_Module == "Site_Map" &&
              <SiteMap />
            }
            {Modules.Select_Module == "Bottom_Search" &&
              <SearchHorizontal />
            }

            {Page.Pagename === "In Person Valuation" && 
              <ValuationFormPage Valuation_Form_BG={Modules.Valuation_Form_BG.childImageSharp.fluid} Title={Modules.Valuation_Title}/>
            }
            {Page.Pagename === "Instruct Us Now" && 
              <BlogValuation Valuation_Form_BG={Modules.Valuation_Form_BG.childImageSharp.fluid} Title={Modules.Valuation_Title} Description={Modules.Valuation_Description}/>
            }
            
            </>
            )

    })}

    </div>
    <Footer footerDetails = {GlobalConfig} popularSearch={Page.Select_Popular_Search} />

  </div>
</>
  )
}
}
export default DefaultTemplate