import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { Button, Container, Row, Col, Form, Table } from 'react-bootstrap';
import { useQuery } from '@apollo/client';
import gql from "graphql-tag"
import ReactMarkdown from "react-markdown/with-html";
import { Logo } from '../components/icon';
import ScrollAnimation from 'react-animate-on-scroll';

const GET_ALL_OPENINGS = gql`
query GetOpenings {    
  careers {
    Job_Opening
    Salary_Range
    URL
  }
}
`;
function CurrentVacancies(props) {
  const { loading: jobsloading, error: jobserror, data: alljobs } = useQuery(GET_ALL_OPENINGS);

  if (jobsloading) return (
    <div className="static-details gql-loading-screen">
        <Container>
        <Logo />
        <div className="gql-loading">Loading ...</div>
        </Container>
    </div>);
    
  return (
    <>
      <section className="reid-team" id="current-vacancies">
        <Container>
        {/* <ScrollAnimation animateIn="animate__fadeInUp" animateOnce={true} > */}
          <div className="team-head text-center">
            <ReactMarkdown source={props.Heading} escapeHtml={false}/>
          </div>
          {/* </ScrollAnimation> */}
          <Row>
          {/* <ScrollAnimation animateIn="animate__fadeInUp" animateOnce={true} > */}
            <div className="table-section">

              <Container>
                {/* <Table responsive>

                  {alljobs.careers.map((job, index) => {
                    return <>
                      <tr>
                        <td>{job.Job_Opening}</td>
                        <td>{job.Salary_Range}</td>
                        <td><Link to={`${job.URL}/`}>More Information</Link></td>
                      </tr>
                    </>
                  })}

                </Table> */}

                <ul className="current-table">
                  {alljobs.careers.map((job, index) => {
                    return <>
                      <li className="text-left">{job.Job_Opening}</li>
                      <li className="text-center mob-text-none">{job.Salary_Range}</li>
                      <li className="text-center mob-text-left"><Link to={`${job.URL}/`}>More Information</Link></li>
                    </>
                  })}
                </ul>
              </Container>
            </div>
            {/* </ScrollAnimation> */}
          </Row>
        </Container>
      </section>
    </>
  )
}
export default CurrentVacancies