import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { Container, Row, Col, Table, Modal } from 'react-bootstrap'; 
import Header2 from "../components/Header/header";
import Footer from "../components/Footer/footer";
import ImageSlider2 from '../components/ImageSlider2/newhomes-slider';
import { doc } from "prettier";
import { useQuery } from '@apollo/client';
import gql from "graphql-tag";
import NewHomesBanner from "../components/newhomes-banner";
import GetAreaGuideName from "../components/get-area-property-name";
import Areaproperty from "../components/Areaproperty";
import Floorplan from "../components/floorplan";
import { Logo } from "../components/icon/icon";
import BookingModal from "../components/book-view-modal2";
import $ from 'jquery'
import axios from 'axios';
import { useLocation } from "@reach/router"
import SEO from "../components/Seo/seo";
import Propertymap from "../components/property-map";
import { Helmet } from "react-helmet";
import ReactMarkdown from "react-markdown/with-html";
import GeneralEnquiryForm from "../components/forms/developments-enquiry";
import Brochure from "../components/forms/download-brochure" 
import SalesForceForm from "../components/forms/salesforce-form"
import NotFoundPage from "../pages/404";

const PROPERTY_DETAILS = gql`
  query GetProperty ($id: String!){
    properties(where:{id: $id}) {
      id
	  publish
      title
      office_crm_id
      price
      price_qualifier
      images
      rent
      building
      address
      brochure
      search_type
      display_address
      room_details
      description
      long_description
      crm_negotiator_id
      floorplan
      epc
      virtual_tour
      latitude
      longitude
	  imagetransforms
	  city
	  rental_yields 
	  Why_Invest
	  Banner_Image{
		url
	  }
	  Brochure_Attach{
		url
	  }
	  Investment_Points{
		Point
	  }
	  Amenities{
		Point
	  }
	  FAQ
	  max_price
    }
  }
`;

function gotohash(event) {
	var thishash = event;
	$('html, body').animate({
		scrollTop: $(thishash).offset().top - 80
	}, 1000);
}
function PropertyDetails(props) { 
	useEffect(() => {
		let scrollPos = 0;
		window.addEventListener('scroll', handleScroll, true);
		function handleScroll(e) {
			let headerElement = document.getElementsByClassName("header")[0];
			if ((document.body.getBoundingClientRect()).top === 0 && headerElement) {
				headerElement.classList.remove("header-fixed")

			} else if ((document.body.getBoundingClientRect()).top < 0 && headerElement) {
				headerElement.classList.add("header-fixed")

			}
			scrollPos = (document.body.getBoundingClientRect()).top;
		}
		return () => {
			window.removeEventListener('scroll', handleScroll);
		}

	}, [])

	const Modules = props.Modules
	const StatsModuleFilter = Modules.filter((Modules) => Modules.Stats != null).map(({ Stats }) => ({ Stats }));
	const StatsModule = StatsModuleFilter[0]
	const AvailibilityTable = Modules.filter((Modules) => Modules.Content_Between_Module != null).map(({ Content_Between_Module }) => ({ Content_Between_Module }));

	const StatTitle = Modules.filter((Modules) => Modules.Statistics_Title != null).map(({ Statistics_Title }) => ({ Statistics_Title }));

	var statheading = ""
	if(StatTitle && StatTitle.length > 0){
		statheading = StatTitle && StatTitle[0].Statistics_Title;
	}

	const location = useLocation();
	const propertyUrl = location.href
	const [show, setShow] = useState(false);
	const [showlife, setShowlife] = useState(true);
	const [propItems, setPropItems] = useState([])
	const getitems = async url => {
		try {
			const { data } = await axios.get(url, {
				headers: {
					Authorization: `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`
				}
			})
			if (data && data.length > 0) {
				setPropItems(data)
			}
		} catch (error) {
			console.error(error)
		}
	}
	useEffect(() => {
		setTimeout(function () {
			var checkarea = $('.property-details .property_areaguide').length;
			if (checkarea == 0) {
				setShowlife(false)
			} else {
				setShowlife(true)
			}
		}, 1500);
		$(".openbooking").on("click", function () {
			$('.triggerbooking').click();
		});
		let url = process.env.GATSBY_STRAPI_SRC + "/properties?department=new_developments&_sort=price:DESC&publish=true";
		getitems(url)



	}, []);


	const [lightboxController, setLightboxController] = useState({
		toggler: false,
		slide: 1
	});

	const openLighbox = (e) => {
		e.preventDefault();
		setLightboxController({
			toggler: !lightboxController.toggler,
			slide: 1
		});
	}


	const GlobalConfig = props.GlobalConfig


	const { loading, error, data } = useQuery(PROPERTY_DETAILS, {
		variables: { id: props.PropertyID }
	});

	if (data?.properties[0]?.publish === false) {
		return (
			<>
				<NotFoundPage />
			</>
		)
	}

	if (loading) return (
		<div className="static-details gql-loading-screen">
			<Container>
				<Logo />
				<div className="gql-loading">Loading ...</div>
			</Container>
		</div>);



	const PropertyImages = "test.jpg"
	var VTour = '', BrochureURL = '', PropVideo = '', propertyCity = '', propertyName = '';
	//console.log("data",data);
	return (
		<div className="wrapper">
			{/* header start */}
			<Header2 />
			{/* header end */}
			{/* content start */}
			<div className="content newhome-details static-page">
				{data.properties.map((property, ind) => {

					if (property.search_type == "lettings") {
						var price = Math.round(property.price)
						var searchtype = 'to rent';
					} else {
						var price = Math.round(property.price)
						var searchtype = 'for sale';
					}
					const metaTitle = property.title + ' in ' + property.display_address
					const metaDesc = 'Book a viewing for this ' + property.title + ' in ' + property.display_address + ' with Orlando Reid at ' + property.price + '. Contact Orlando Reid, and get assisted for all your property needs.'

					var fullDESC = property.long_description
					fullDESC = fullDESC.replace(/FULLY/gi, "");
					fullDESC = fullDESC.replace(/MANAGED - /gi, "");
					fullDESC = fullDESC.replace(/FULL/gi, "");
					fullDESC = fullDESC.replace(/DESCRIPTION/gi, "");
					
					if (property.virtual_tour != null && property.virtual_tour != undefined) {
						if (property.virtual_tour && Object.keys(property.virtual_tour).length > 0) {
							VTour = JSON.parse(property.virtual_tour).value;
						}
						if (Object.keys(property.virtual_tour).length > 0 && property.virtual_tour && JSON.parse(property.virtual_tour).length >= 2) {
							VTour = JSON.parse(property.virtual_tour)[0].value
						}
						if (Object.keys(property.virtual_tour).length > 0 && property.virtual_tour && JSON.parse(property.virtual_tour).length >= 2) {
							PropVideo = JSON.parse(property.virtual_tour)[1].value
						}
					}
					if (property.brochure && Object.keys(property.brochure).length > 0) {
						BrochureURL = property.brochure[0].url;
					}
					if(property.Brochure_Attach){
						BrochureURL = property.Brochure_Attach.url;
					}
					propertyCity = property.city
					propertyName = property.display_address;

					// console.log('BrochureURL', BrochureURL)
					return <>
						{/* section banner start */}
						<SEO title={metaTitle} description={metaDesc} />
						<Helmet>
							<script type="application/ld+json">{`{
                                "@context" : "http://schema.org",
                                "@type" : "Product",
                                "name" : "[${metaTitle}]",
                                "image" : "[${(property.Banner_Image && property.Banner_Image.length > 0) && property.Banner_Image[0].url}]",
                                "description" : "[${metaDesc}]",
                                "brand" : {
                                    "@type" : "Organization",
                                    "name" : "Orlando Reidl",
                                    "logo" : "https://ggfx-orlandoreid.s3.eu-west-2.amazonaws.com/i.prod/OR_Logo_115effa5b8.jpg"
                                },
                                "offers": {
                                    "@type": "Offer",
                                    "url": "[${propertyUrl}]",
                                    "priceCurrency": "GBP",
                                    "price": "[${price}]",
                                    "availability": "https://schema.org/InStock"
                                }
                            }`}</script>
						</Helmet>
						<NewHomesBanner  BannerTitle={property.display_address} price={price} proptitle={property.title} propertyurl={propertyUrl} officecrmid={property.office_crm_id} propertyimages={property.Banner_Image && property.Banner_Image.length > 0 ? property.Banner_Image : property.images} videotour={VTour} PropVideo={PropVideo} id={property.id} imagetransforms={property.imagetransforms} />

						{/* section text start */}
						<section className="section-text">
							<nav className="secondary-nav d-none">
								<Container>
									<ul className="d-flex">
										<li>
											<a onClick={() => gotohash('#description-section')} href="javscript:;">description</a>
										</li>

										<li>
											<a onClick={() => gotohash('#map-section')} href="javscript:;">map</a>
										</li>

										{property.floorplan.length > 0 && property.floorplan &&
											<Floorplan floorplan={property.floorplan} />
										}
										{BrochureURL != '' &&
											<li>
												<a href={BrochureURL} target="_blank">brochure</a>
											</li>
										}
										{showlife &&
											<li className="scrolllocalarea">
												<a onClick={() => gotohash('#local-life')} href="javscript:;">local life</a>
											</li>
										}
									</ul> 
								</Container>
							</nav>

							<Container>
								<Row>
									<Col md={7} lg={8}>
										<div className="intro">
											<h1>{property.display_address}</h1>
											<ul className="features">
												<li className="info location-info">
													<span className="title">Location</span> <p>{property.city}</p>
												</li>
												<li className="info prices-info">
													<span className="title">Prices from</span> <p>£{property.price.toLocaleString()}{property.max_price ? ` - £${property.max_price.toLocaleString()}` : ''}</p>
												</li>
												{
													property.rental_yields &&
													<>

														<li className="info yields-info">
															<span className="title">Rental Yields</span> <p>{property.rental_yields}</p>
														</li>

													</>
												}
												{
													property.building &&
													<>

														<li className="info inverst-info">
															<span className="title">Apartment Type</span> <p>{property.building}</p>
														</li>

													</>
												}
												<li className="info broucher-info">
													<span className="title">Brochure</span> <p>	<a onClick={() => setShow(true)} href="javscript:;">Download</a></p>
												</li>

											</ul>
										</div>
										<div className="content">
											{property.address?.street &&
												<h2>{property.address?.street} overview</h2>
											}
											
											<ReactMarkdown source={property.description} escapeHtml={false} />
										</div>
										<div className="content highlights"> 
											<h2>Investment Highlights</h2> 
											<ul className="points">
											{property.Investment_Points && property.Investment_Points.map((item, key) => {  
												return (
													<li>{item.Point}</li>
												)
											})}
											</ul> 
										</div>
										{property.Why_Invest &&
										<div className="content"> 
											{property.address?.street &&
												<h2>Why invest in {property.address?.street}?</h2>
											}  
											<ReactMarkdown source={property.Why_Invest} escapeHtml={false} />
										</div>
										}
										<div className="content highlights"> 
											<h2>Amenities</h2> 
											<ul className="points">
											{property.Amenities && property.Amenities.map((item, key) => {  
												return (
													<li>{item.Point}</li>
												)
											})}
											</ul>
										</div>
										{property.FAQ &&
										<div className="content">
											{property.address?.street &&
												<h2>Frequently Asked Questions</h2>
											}
											<ReactMarkdown source={property.FAQ} escapeHtml={false} />
										</div>
										}
										{/* section map start */}
										<div className="full-map-area" id="map-section">
											<Propertymap data={property} Latitude={property.latitude} Longitude={property.longitude} />
										</div>
										{/* section map end */}
										<div className="d-block d-md-none enquirybtn">
										<BookingModal propertyurl={propertyUrl} propertyName={property.display_address}  propertyCity={property.city} officecrmid={property.office_crm_id} />
										</div>
									</Col>

									<Col md={5} lg={4} className="d-none d-md-block">
										{/* newsletter block start */}
										<div className="newsletter-wrap">
											{property.city.toLowerCase() == "manchester" ?
												<>
													<h2>Request further information</h2>
													<SalesForceForm className="sidebar" propertyName={property.display_address}  retURL={process.env.GATSBY_SITE_URL+"/thank-you-for-request-development-information"}  /> 
												</>
											:
												<GeneralEnquiryForm Heading={"Request further information"} propertyName={property.display_address}  propertyurl={propertyUrl} to_email_id={property.city.toLowerCase() == "london" ? "sales@orlandoreid.co.uk" : "manchester@orlandoreid.co.uk"}/> 
											}
											 
											

										</div>
										{/* newsletter block end */}
									</Col>

								</Row>
							</Container>
						</section>
						{/* section text end */}

						{/* section explore start */}
						<div id="local-life">
							{props.resultspage === "newsalespage" && property.address.town && <GetAreaGuideName slug={property.address.town}  postcode={property.address.postcode ? property.address.postcode.split(" ")[0] : ''} officecrmid={property.office_crm_id}/> }
							
						</div>
						{/* section explore end */}
						{/* similar properties start */}
						{propItems && propItems.length > 0 &&
							<section className="section-properties similar-properties">
								<Container className="properties animate__animated">
									{propItems && propItems.length > 0 ?
										<div className="head-sec text-center">
											<h2>Other Investment Opportunities</h2>
										</div>
										: ''}
									{propItems && propItems.length > 0 ? <ImageSlider2 id={props.PropertyID} ptype="newhomes" data={propItems} Title={`Similar Properties in ${property.address.address1}, London`} /> : ''}


								</Container>
							</section>
						}
						{/* similar properties end */}
					</>
				})}

			</div>
			{/* content end */}
			{/* footer start */}
			<Footer popularSearch='Popular_Search_Static_Common' propertydetails={data.properties} footerDetails={GlobalConfig} />

			{/* footer end */}

			<Modal
				show={show}
				centered={true}
				onHide={() => setShow(false)}
				dialogClassName="modal-90w modal-form"
				aria-labelledby="example-custom-modal-styling-title"
			>
				<Modal.Header closeButton>
					<Modal.Title id="example-custom-modal-styling-title">
					Download A Brochure
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
				{propertyCity.toLowerCase() == "manchester" ? 
						<SalesForceForm className="sidebar" propertyName={propertyName} retURL={process.env.GATSBY_SITE_URL+"/thank-you-for-development-brochure-download"} />  
				:
					<Brochure 
					brochure={BrochureURL} 
					subject={props?.Title} 
					to_email_id={propertyCity == "London" ? "sales@orlandoreid.co.uk" : "manchester@orlandoreid.co.uk"}
					propertyName={propertyName}
					/> 
				}
				
				</Modal.Body>
			</Modal>

		</div>
	)

}

export default PropertyDetails
