import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { Button, Container, Row, Col, Form } from 'react-bootstrap';
import Gallery1 from "../images/gallery1.jpg";
import Fade from 'react-reveal/Fade';
import "animate.css/animate.css";
import ReactMarkdown from "react-markdown/with-html"
import YouTube from '@u-wave/react-youtube';
import getVideoId from 'get-video-id';
import $ from 'jquery';
import { VideoPlayButton } from './icon';
import ImageTransform from "./common/ggfx-client/module/components/image-transform";
// import ScrollAnimation from 'react-animate-on-scroll';

const Areaguides = (props)=>{
  const [showVideo, setShowVideo] = React.useState(false);
  const [videoindex, setVideoindex] = React.useState(false);
  const [isPlay,setPlay] = React.useState(false);
    const playVideo = (video_index, video_id) => {
      setVideoindex(video_index)   
      setShowVideo(true)
    }
    const trackerVideo = (event) => {    
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'Video Play Btn',
        'formType': event,
        'formId': 'Video Play',
        'formName': 'Video',
        'formLabel': 'Video'
      });
    }
      return (
        <>
      <section className="sec-location">
      {/* <ScrollAnimation animateIn="animate__fadeInUp" animateOnce={true} > */}

        <Container>
          <Row>
          {props.data.edges.map(({node},index) => {
            if(node.Embed_Video_URL !=null ) {
            var videoid = getVideoId(node.Embed_Video_URL);
            }
            let processedImages = JSON.stringify({});
            if (node?.imagetransforms?.Tile_Image_Transforms) {
                processedImages = node.imagetransforms.Tile_Image_Transforms;
            }
            const image_url = node.Tile_Image && node.Tile_Image.internal.description ? node.Tile_Image.internal.description.replace("File ","").replace('"','').replace('"','') : '';  
            return<>
            {node.Featured == true && node.Select_Areaguide_Location == 'Manchester' &&
              <Col md={6} className="col-xl-4">
              <div className={`location-wrap news-img team_img_${index}`}>
              <Link to={`${node.URL}/`}>
              <div className="bwimg">
                <ImageTransform imagesources={image_url} renderer="srcSet" imagename="areaguides.Tile_Image.listimg"attr={{ alt: node.Title+ ' - Orlando Reid' }} imagetransformresult={processedImages} id={node.strapiId}/>
                </div>
                </Link>
                {node.Embed_Video_URL !=null && node.Embed_Video_URL != '' &&
                <button className="btn-play" onClick = { (e) => {playVideo(index, videoid.id)}}>
                  <VideoPlayButton />
                </button>
                }
                <div className="loc-details text-center">
                  <h3>{node.Title}</h3>
                  <p>{node.Intro_Caption.length > 100 ? node.Intro_Caption.substring(0, 100) + "..."
                  : node.Intro_Caption
                  }</p>
                  <Link className="btn-view" to={`${node.URL}/`}>explore {node.Title}</Link>
                </div>
                {showVideo && videoindex== index &&
                        <YouTube
                        video={videoid.id}
                        autoplay
                        onEnd={e => {setPlay(false);setShowVideo(false)}}
                        modestBranding={1}
                        onPlaying={trackerVideo(node.Title)}

                        />
                }
              </div>
            </Col>
            }
            </>
          })}
        </Row>
        </Container>
        {/* </ScrollAnimation> */}

      </section>
      {/* <ScrollAnimation offset={50} animateIn="animate__fadeInUp" animateOnce={true} > */}

      <section className="sec-location sec-gallery">

        <Container>
          
        <ReactMarkdown source={props.midcontent} escapeHtml={false}/>
          <Row>
          {props.data.edges.map(({node},index) => {
            if(node.Embed_Video_URL !=null ) {
            var videoid = getVideoId(typeof(node.Embed_Video_URL) ==='string' ? node.Embed_Video_URL : "");
            }
            let processedImages = JSON.stringify({});
            if (node?.imagetransforms?.Tile_Image_Transforms) {
                processedImages = node.imagetransforms.Tile_Image_Transforms;
            }
            const image_url = node.Tile_Image && node.Tile_Image.internal.description ? node.Tile_Image.internal.description.replace("File ","").replace('"','').replace('"','') : '';  
            return<>
            {node.Featured == false && node.Select_Areaguide_Location == 'Manchester' &&
              <Col md={6} className="col-xl-4">
              <div className={`location-wrap news-img team_img_${index}`}>
              <Link to={`${node.URL}/`}>
              <div className="bwimg">
                <ImageTransform imagesources={image_url} renderer="srcSet" imagename="areaguides.Tile_Image.listimg"attr={{ alt: node.Title+ ' - Orlando Reid' }} imagetransformresult={processedImages} id={node.strapiId}/>
                </div>
                </Link>

                {node.Embed_Video_URL !=null && node.Embed_Video_URL != '' &&
                <button className="btn-play" onClick = { (e) => {playVideo(index, videoid.id)}}>
                  <VideoPlayButton />
                </button>
                }
                <div className="loc-details text-center">
                  <h3>{node.Title}</h3>
                  <p>{node.Intro_Caption.length > 100 ? node.Intro_Caption.substring(0, 100) + "..."
                  : node.Intro_Caption
                  }</p>
                  <Link className="btn-view" to={`${node.URL}/`}>explore {node.Title}</Link>
                </div>
                {showVideo && videoindex== index &&
                        <YouTube
                        video={videoid.id}
                        autoplay
                        onEnd={e => {setPlay(false);setShowVideo(false)}}
                        modestBranding={1}
                        onPlaying={trackerVideo(node.Title)}

                        />
                }
              </div>
            </Col>
            }
            </>
          })}
        </Row>
        </Container>

      </section>   
      {/* </ScrollAnimation> */}
   
      </>
      )
    }
    export default Areaguides