import React, { useState, useEffect } from "react"

import { Form } from "react-bootstrap"
import { Link } from "gatsby";
import User1 from "../../images/Matt_Hope_68f5a6aeee.webp";

import InputField from './elements/input'
import SelectField from './elements/select'
import TextAreaField from './elements/textarea'
import CheckboxField from './elements/checkbox'
import RadioField from './elements/radio'
import ButtonField from './elements/button'
import HtmlBox from './elements/html'
import ReCaptchaBox from './elements/recaptcha'
import { postFormData } from "./api/Api"
import GetThankyouStaff from './get-valuation-thankyou-staff'
import axios from "axios"
import * as qs from "query-string"
import $ from "jquery"

function MySimpleForm(props) {
  const [startDate, setStartDate] = useState();
  const [valdatetime, setvaldatetime] = useState();
  const [enqtype, setenqtype] = useState();
  const [validated, setValidated] = useState(false);
  const [validated2, setValidated2] = useState(false);
  const [validated3, setValidated3] = useState(false);
  const [showerror, setShowerror] = useState(false);
  const [showerror2, setShowerror2] = useState(false);
  const [showerror3, setShowerror3] = useState(false);

  const [showthankyou, setThankyou] = useState(false);

  const [formvalues, setFormvalues] = useState("");

  const [token, setToken] = useState("");

  const myRef = React.createRef();

  const recaptchaRef = React.createRef();

  const fields = ([
      {
        element: "config",
        formname: "BlogValuation",
        form_type: "contact",
        error_text: "Highlighted fields are required",
        success_text: "Thank you for contacting us. A member of our team will contact you shortly.",
        email_temp_user: "blog_valuation_user",
        email_temp_admin: "blog_valuation_admin",
        email_server_func: "blogvaluation",
        event_tracking: "blogvaluation",
        page_url: "/general-enquiry"
      },  
      {
        text: `${props?.Title ? props?.Title : 'We just need some details so that we can get in touch.'}`,
        element: "heading",
        class: "form-heading",
        step: "",
      },  
      {
        text: `${props?.description ? props?.description : ""}`,
        element: "heading",
        class: "form-description",
        step: "",
      },   
      {
        grpmd: "12",
        label: "Sell my property",
        placeholder: "Sell my property",
        value: "Sell my property",
        name: "enquirytype",
        type: "radio",
        element: "radio",
        checked:"checked",
        required: true,
        patternchk: "^[-a-zA-Z0-9-()]+(\\s+[-a-zA-Z0-9-()]+)*$",
        labelClass: "content_b-18",
        step: ""
      },
      {
        grpmd: "12",
        label: "Let my property",
        placeholder: "Let my property",
        name: "enquirytype",
        value: "Let my property",
        type: "radio",
        element: "radio",
        required: true,
        patternchk: "^[-a-zA-Z0-9-()]+(\\s+[-a-zA-Z0-9-()]+)*$",
        labelClass: "content_b-18",
        step: ""
      },
      
      {
        grpmd: "12",
        label: "Name",
        placeholder: "Your Name",
        name: "name",
        type: "text",
        element: "input",
        step: "",
        required: true,
        patternchk: "^[-a-zA-Z0-9-()]+(\\s+[-a-zA-Z0-9-()]+)*$",
        labelClass: "content_b-18"
      },
      {
        grpmd: "12",
        label: "Telephone",
        placeholder: "Your Telephone Number",
        name: "telephone",
        step: "",
        type: "text",
        element: "input",
        required: true,
        labelClass: "content_b-18"

      },
      {
        grpmd: "12",
        label: "Email",
        placeholder: "Your Email Address",
        name: "email",
        type: "email",
        element: "input",
        step: "",
        required: true,
        patternchk:"[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$",
        labelClass: "content_b-18"

      },
      
      {
        grpmd: "12",
        label: "Address",
        placeholder: "Address",
        name: "address",
        type: "text",
        element: "input",
        step: "",
        required: true,
        patternchk: "^[-a-zA-Z0-9-()]+(\\s+[-a-zA-Z0-9-()]+)*$",
        labelClass: "content_b-18"
      },
      {
        text: 'By clicking instruct us now, you agree to our <a href="/terms-and-conditions/">Terms & Conditions</a> and <a href="/privacy-policy/">Privacy Policy</a>',
        element: "html",
        class: "terms",
        step: ""
      },
      {
        grpmd: "12",
        name: "Instruct us now",
        type:"submit",
        element: "button",
        value: "submit",
        class: "btn btn-success",
        labelClass: "content_b-18",
        step: ""
      },
      {
        element: "captcha",
        class: "py-2",
        captchaRef: recaptchaRef
      },
    ]);

  const handlechange = event => {
    // remove initial empty spaces
    event.target.value = event.target.value.trimStart()
  }
  const getradio = event => {
    event.target.value = event.target.value.trimStart()
    setenqtype(event.target.value)
  }
  useEffect(() => {
    $('#enquirytypechecked').attr('checked',true)
    if (token !== '') {

 const processFromData = async () => {

      let formData = new FormData();

      formData.append('data', JSON.stringify(formvalues));

      postFormData(formData).then(apiRes => {

      formvalues['g_recaptcha_response'] = token;

      // lets send mail
      const axiosOptions_email = {
        url: '/.netlify/functions/server/'+fields[0].email_server_func,
        method: "post",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        data: qs.stringify(formvalues),
      }

      axios(axiosOptions_email)
        .then(response => {
          console.log('mail sent!')
        })
        .catch(err =>
          console.log(err)
        );
      });

      const axiosOptions_netlify = {
        url: fields[0].page_url,
        method: "post",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        data: qs.stringify(formvalues),
      }

      axios(axiosOptions_netlify)
        .then(response => {
          console.log('data stored')
        })
        .catch(err =>
          console.log(err)
        );
      const url = typeof window !== 'undefined' ? window.location.href : ''  
      if (url.indexOf("property") > -1 && fields[4].event_tracking_decider) {
        fields[0].formname = 'Get mortgage help';
      }
      // tracking event
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'formSubmit',
        'formType': 'form-'+fields[0].event_tracking,
        'formId': 'form-'+fields[0].event_tracking,
        'formName': fields[0].formname,
        'formLabel': fields[0].formname
      });

      setShowerror(false);
      setThankyou(true);
      //myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })

      }
      processFromData();

    }
  }, [token]);

  const handleonVerify = token => {
    console.log("captcha verified");
    setToken(token);
  };

  function onClickstep1() {
      setShowerror(true);
      setValidated(true);
      var valname  = $("input[name=name]").val();
      var valemail = $("input[name=email]").val();
      var valtel   = $("input[name=telephone]").val();
      var phoneNum = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/; 
      var telvalidation = false
      var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      var emailvalidation = false
      if(valemail.match(mailformat)) {
        emailvalidation = true
      } else {
        emailvalidation = false
      }

      if(valtel.match(phoneNum)) {
        telvalidation = true
      } else {
        telvalidation = false
      }
      //alert(telvalidation)
      //alert(emailvalidation)
      if(valname && valemail && valtel && emailvalidation == true) {
        setShowerror(false);
        setValidated(false);
        $('.valuation-main .stepone').hide();
        $('.valuation-main .steptwo').show();  
        $('.valuation-main .steptwo .form-heading').html('Thanks '+valname+', now we need your property details.') 
      }
  }
  function onClickstep2() {
    setShowerror(true);
    setValidated(true);
    var valenqtye   = $("input[name=enquirytype]").val();
    var valpost     = $("input[name=postcode]").val();
    if(valenqtye && valpost) {
        setShowerror(false);
        setValidated(false);
        $('.valuation-main .steptwo').hide();
        $('.valuation-main .stepthree').show();
    }
  }
  const handleSubmit = event => {

    const form = event.currentTarget;
    const formsdata = (event.target);
    
    setvaldatetime($("input[name=date_time]").val())
    setenqtype($("input[name=enquirytype]:checked").val())
    $("input[name=enquirytype]").val('Sell my property')
    if(enqtype) {
      $("input[name=enquirytype]").val(enqtype)
    }
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setShowerror(true);
      setValidated(true);
      setThankyou(false);      
      //myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }
    else {
      $('.valuation-main .stepthree').hide();
      event.preventDefault();

      const formsdata = (event.target);
      const json = {}
      Object.keys(formsdata).map(key => (
        json[formsdata[key].name] = (formsdata[key].checked) ? enqtype : formsdata[key].value
      ))
      json['email_temp_user'] = fields[0].email_temp_user;
      json['email_temp_admin'] = fields[0].email_temp_admin;
      json['formname'] = fields[0].formname;
      json['g_recaptcha_response'] = token;

      setFormvalues(json);

      recaptchaRef.current.execute();

      setValidated(false);

      // reset form
      const form = event.target
      //form.reset();
      setStartDate()
    }
  };

  //var valdatetime = $("input[name=date_time]").val();

  return (
    <div className="form stbform valuation-main">

    {showerror && <div className="alert-error stepone">
      <p>{fields[0].error_text}</p>
    </div>}
    {showerror2 && <div className="alert-error steptwo">
      <p>{fields[0].error_text}</p>
    </div>}
    {showthankyou && <div className="alert-success">
      <p>Great! We will get back to you shortly!</p>
      <div class="btn-wrap">
      <a class="btn btn-success" href="https://www.bricksandlogic.co.uk/agent-tools/avm/valuation/orlandoreid" target="_blank">Book an instant Valuation</a>
      <a class="btn btn-success" href="/contact/">contact us</a>
      </div>
      <GetThankyouStaff thankyoustaff={enqtype == 'Sell my property'?'Matt Hope':enqtype == 'Let my property' ? 'Alberts Marques' : ""}/>
    </div>}

    
      {!showthankyou && <Form className="contact-form" name={fields[0].formname} action="/thank-you/" method="post" noValidate validated={validated} onSubmit={handleSubmit} data-netlify="true" netlify-honeypot="bot-field" data-netlify-recaptcha="true" autoComplete={false}>
        <input type="hidden" name="form_name" value={fields[0].formname} />
        <input type="hidden" name="form_type" value={fields[0].form_type} />
        <input type="hidden" name="to_email_id" value={props.to_email_id} />
        <input type="hidden" name="bot-field" />
        {fields.map((field, index) => {

            if ( "input" === field.element ) {
              return (
                <InputField
                  name={field.name}
                  grpmd={field.grpmd}
                  ref={field.ref}
                  step={field.step}
                  type={field.type}
                   startDate={field.type === 'datetime' ? startDate : ''}
                  setStartDate={field.type === 'datetime' ? setStartDate : ''}                                   
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  labelClass={field.labelClass}
                  required={field.required}
                  key={`${field.element}~${index}`}
                  pattern={field.patternchk}
                  handlechange={handlechange}
                />
              );
            }
            if ("select" === field.element) {
              return (
                <SelectField
                  name={field.name}
                  grpmd={field.grpmd}
                  ref={field.ref}
                  required={field.required}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  values={field.values}
                  key={`${field.element}~${index}`}
                  handlechange={handlechange}
                  componentprops={props}
                />
              );
            }
            if ("textarea" === field.element) {
              return (
                <TextAreaField
                  name={field.name}
                  grpmd={field.grpmd}
                  ref={field.ref}
                  rows={field.rows}
                  fieldClass={field.class}
                  labelClass={field.labelClass}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.element}~${index}`}
                  handlechange={handlechange}
                />
              );
            }
            if ("checkbox" === field.element) {
              return (
                <CheckboxField
                  name={field.name}
                  ref={field.ref}
                  value={field.value}
                  fieldClass={field.class}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.name}~${index}`}
                  handlechange={handlechange}
                />
              );
            }
            if ("radio" === field.element) {
              return (
                <RadioField
                  customClass={`custom_radio-${index}`}
                  name={field.name}
                  ref={field.ref}
                  value={field.value}
                  fieldClass={field.class}
                  checked={field.checked}
                  placeholder={field.placeholder}
                  required={field.required}
                  key={`${field.name}~${index}`}
                  handlechange={getradio}                  
                  step={field.step}
                />
              );
            }
            
            if ("heading" === field.element) {
              return (
                <HtmlBox
                  text={field.text}
                  fieldClass={field.class}
                  key={`${field.element}~${index}`}
                  step={field.step}
                />
              );
            }
            if ("captcha" === field.element) {
              return (
                <ReCaptchaBox
                  fieldClass={field.class}
                  captRef={field.captchaRef}
                  key={`${field.element}~${index}`}
                  handleonVerify={handleonVerify}
                />
              );
            }
            if ("button" === field.element) {
              return (
                <ButtonField
                  name={field.name}
                  fieldClass={field.class}
                  type={field.type}
                  step={field.step}
                  value={field.value}
                  key={`${field.element}~${index}`}
                                                 
                />
              );
            }
          })
        }
            <div ref={myRef} />

      </Form>}
    </div>
  );
}


const MySimpleFormPage = (props) => (
  <MySimpleForm   Title={props.Title} description={props.description}/>
)

export default MySimpleFormPage