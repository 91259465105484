import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { Button, Container, Row, Col, Form } from 'react-bootstrap';
import GetCTA from "./get-cta"
import { useMatch } from "@reach/router"

const Offices = (props) => {
    const manchesteroffice = useMatch("/contact/our-offices/:item/")
    return (
        <div className="banner-content text-center office-btns d-xl-block">
            <div class="btn-wrap">
               {manchesteroffice && manchesteroffice?.item!=null && manchesteroffice?.item === "manchester-offices" ?
                <a href = "/contact/general-enquiry/?contactoffice=manchester-property-investments" className = "btn btn-success">{props.CTA1}</a>
                :
                <GetCTA link = {props.CTA1Url} classname = "btn btn-success" label={props.CTA1}/>
                }
                <GetCTA link = {props.CTA2Url} classname = "btn btn-default" label={props.CTA2}/>
            </div>
        </div>
    )
}
export default Offices