import React, { useState, useEffect } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import ImgStar from "../images/icon-star.svg";
import axios from "axios"
import TimeAgo from 'javascript-time-ago'
import ShowMoreText from 'react-show-more-text';

// English.
import en from 'javascript-time-ago/locale/en'

TimeAgo.addDefaultLocale(en)

// Create formatter (English).

const postsPerPage = 20;
let arrayForHoldingPosts = [];



const StaticPage = () => {
  /**** set here local states */
  const [] = useState(0);

  const [postsToShow, setPostsToShow] = useState([]);
  const [next, setNext] = useState(20);

  const [] = useState(true);
  const [testimonials, setTestimonials] = useState([])

  const loopWithSlice = (start, end) => {
    const slicedPosts = testimonials.slice(start, end);
    arrayForHoldingPosts = [...arrayForHoldingPosts, ...slicedPosts];
    setPostsToShow(arrayForHoldingPosts);
  };

  const handleShowMorePosts = () => {
    loopWithSlice(next, next + postsPerPage);
    setNext(next + postsPerPage);
  };



  const getitems = async url => {
    try {
      const { data } = await axios.get(url, {        
        headers: {
          Authorization: `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`
        }               
      })// could be from env files
      setTestimonials(data)
    } catch (error) {
      // cache it if fail/error;
      console.error(error)
    }
  }

  useEffect(() => {
    loopWithSlice(0, postsPerPage);
  }, [testimonials]);


  // header-fixed
  /** end local state area */
  useEffect(() => {
    let url = `${process.env.GATSBY_STRAPI_SRC}/google-review-reviews`
    getitems(url);



    let scrollPos = 0;
    window.addEventListener('scroll', handleScroll, true);
    function handleScroll() {
      let headerElement = document.getElementsByClassName("header")[0];
      if ((document.body.getBoundingClientRect()).top === 0) {
        headerElement.classList.remove("header-fixed")
        // headerElement.classList.add("fixed-top")
      } else if ((document.body.getBoundingClientRect()).top < 0) {
        headerElement.classList.add("header-fixed")
        //headerElement.classList.remove("fixed-top")
      }
      scrollPos = (document.body.getBoundingClientRect()).top;
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
    }




  }, [])





  return (
    <>


      {testimonials.length !== 0 &&

        <div className="static-page">
          <section className="sec-review">
            <Container>
              <Row>
                {postsToShow.map((review) => {
                  if (review.starRating != "ONE" && review.starRating != "TWO" && review.starRating != "THREE") {
                    return <>
                      <Col md={6} className="myList">
                        <div className="card-rating">
                          <ul className="rating-star d-flex">
                            {review.starRating == "ONE" &&
                              <>
                                <li>
                                  <img src={ImgStar} alt="img" />
                                </li>

                              </>
                            }
                            {review.starRating == "TWO" &&
                              <>
                                <li>
                                  <img src={ImgStar} alt="img" />
                                </li>
                                <li>
                                  <img src={ImgStar} alt="img" />
                                </li>

                              </>
                            }
                            {review.starRating == "THREE" &&
                              <>
                                <li>
                                  <img src={ImgStar} alt="img" />
                                </li>
                                <li>
                                  <img src={ImgStar} alt="img" />
                                </li>
                                <li>
                                  <img src={ImgStar} alt="img" />
                                </li>

                              </>
                            }
                            {review.starRating == "FOUR" &&
                              <>
                                <li>
                                  <img src={ImgStar} alt="img" />
                                </li>
                                <li>
                                  <img src={ImgStar} alt="img" />
                                </li>
                                <li>
                                  <img src={ImgStar} alt="img" />
                                </li>
                                <li>
                                  <img src={ImgStar} alt="img" />
                                </li>

                              </>
                            }
                            {review.starRating == "FIVE" &&
                              <>
                                <li>
                                  <img src={ImgStar} alt="img" />
                                </li>
                                <li>
                                  <img src={ImgStar} alt="img" />
                                </li>
                                <li>
                                  <img src={ImgStar} alt="img" />
                                </li>
                                <li>
                                  <img src={ImgStar} alt="img" />
                                </li>
                                <li>
                                  <img src={ImgStar} alt="img" />
                                </li>
                              </>
                            }
                          </ul>
                          <p className="more">
                            <ShowMoreText
                              /* Default options */
                              lines={3}
                              more='Show more'
                              less='Show less'
                              className='content-css'
                              anchorClass='btn-more review-more'
                              expanded={false}
                              width={508}
                            >

                              {review.comment}
                            </ShowMoreText>

                          </p>
                          <div className="user-profile d-flex align-items-center">
                            <figure className="profile-img">
                              <img src={review.reviewer.profilePhotoUrl} alt="img" />
                            </figure>
                            <div className="profile-desc">
                              <span className="user-name">{review.reviewer.displayName}</span>
                              <span className="post-time">
                                {review.createTime.slice(0, 10)}
                              </span>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </>
                  }
                })}

                <button className="btn btn-default btn-pagination" onClick={handleShowMorePosts}>Load more</button>

              </Row>
            </Container>
          </section>


        </div>
      }

    </>
  )
}

export default StaticPage