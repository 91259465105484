import React from "react";
import { Container, Col, Row, Form } from 'react-bootstrap';
import Breadcrumbs from "../components/breadcrumbs";
//import ValuationForm from "../components/forms/valution-form";
import BlogValuationForm from "../components/forms/blog-valuation-form";
import Img from 'gatsby-image';
import { useLocation } from "@reach/router";


function BlogValuation(props) {
//   var { pathname } = useLocation()
//   const pathArray = ['/property-valuation/free-property-valuation-in-clapham-battersea-and-manchester', '/property-valuation/free-property-valuation-in-clapham-battersea-and-manchester/']
//   let isPath = pathArray.includes(pathname);

  return (
  <>
<section className="section-contact team-details">
          <div className="contact-img contact-imgx animate__animated">
          <Breadcrumbs/>
          <Img fluid={props.Valuation_Form_BG} alt={`Property Valuation - Orlando Reid`}/>
          </div>
          <Container>
            <Row className="justify-content-xl-end">
              <Col className="col-xl-6 textContact blog-valuation animate__animated ">
                <BlogValuationForm Title={props?.Title} description={props.Description}/>
               
              </Col>
            </Row>
          </Container>
  </section>  
  </>
)
}
export default BlogValuation

