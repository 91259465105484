import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { Container, Row, Col, Breadcrumb, Form } from 'react-bootstrap';
import Subscribe from "../images/subscribe.png";
import $ from 'jquery'
import { navigate } from "@reach/router"

const News = (props) => {
	// search results page navigation
    useEffect(() => {
        let url = "/";
  
        $(".sales_btn").click(function() {
            var searcValue = $(".search_field_text").val();
            if(searcValue !== "") {
                url = "in-"+searcValue+"/"
            } else {
                url = "in-london/"
            }
            navigate("/property/for-sale/"+url);
        })
  
        $(".rent_btn").click(function() {
            var searcValue = $(".search_field_text").val();
            if(searcValue !== "") {
                url = "in-"+searcValue+"/"
            } else {
                url = "in-london/"
            }
            navigate("/property/to-rent/"+url);
        })
    },[]);
    // search results page navigation

	return (<React.Fragment>


{/* newsletter block start */}
					<Form className="newsletter-block bottom-search-block">
						<h2>
                        Discover a wide range of property for sale or rent in London.
						</h2>
						<div className="field-wrap d-md-flex flex-md-wrap">
							<Form.Group controlId="formBasicEmail">
								<Form.Control className="search_field_text" type="text" placeholder="Your Postcode" />
							</Form.Group>

							<div className="btn-wrap">
                                <button className="btn btn-success sales_btn" type="submit">
                                Buy
                                </button>
							</div>
                            <div className="btn-wrap">
                                <button className="btn btn-success rent_btn" type="submit">
                                Rent
                                </button>
							</div>
						</div>

					</Form>
					{/* newsletter block end */}

                    </React.Fragment>)

}

export default News
