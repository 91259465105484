import React from 'react';
import Slider from "react-slick";
import Img4 from "../../images/img4.jpg";
import Img5 from "../../images/img5.jpg";
import Img6 from "../../images/img6.jpg";
import "slick-carousel/slick/slick.css";
import ImageTransform from "../common/ggfx-client/module/components/image-transform";
import { Link } from "gatsby";
const ImageSlider = (props) => {

    const NextArrow = (props) =>  {
        const { className, style, onClick } = props;
        return (
          <div
            className={className}
            style={{ ...style, display: "block" }}
            onClick={onClick}
          >
              <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40"   fill="#81BB27"  viewBox="0 0 20 20"><path d="M10 20A10 10 0 1 0 0 10a10 10 0 0 0 10 10zM8.711 4.3l5.7 5.766L8.7 15.711l-1.4-1.422 4.289-4.242-4.3-4.347z"/></svg>
            </div>
        );
      }
      
    const PrevArrow = (props) => {
        const { className, style, onClick } = props;
        return (
          <div
            className={className}
            style={{ ...style, display: "block" }}
            onClick={onClick}
          >
           <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="#81BB27"  viewBox="0 0 20 20"><path d="M10 20A10 10 0 1 0 0 10a10 10 0 0 0 10 10zm1.289-15.7 1.422 1.4-4.3 4.344 4.289 4.245-1.4 1.422-5.714-5.648z"/></svg>
          </div>
        );
      }
    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        autoplay: false,
        arrows: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1800,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    centerPadding: '192px',
                }
            },
            {
                breakpoint: 1293,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    centerPadding: '140px',
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    centerPadding: '163px',
                    dots: false,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: false,
                    centerPadding: '0px',
                    dots: false,
                }
            }
        ]
    };
    return (
        <div className="property-slider">
            <Slider {...settings}  className="custom-slider">
                {props.data.map((item, key) => {
                    let processedImages = JSON.stringify({});
                    if (item?.imagetransforms?.images_Transforms) {
                        processedImages = item.imagetransforms.images_Transforms;
                    }
                    var checkptype = item.search_type === "sales" ? '/new-development-for-sale/' : '/new-development-to-rent/'
                    if (item.id !== props.id && item.images && item.images[0]) {
                        return <div className="property-block text-center">

                            <figure className="property-img">
                                {item.Banner_Image && item.Banner_Image[0] &&
                                    <ImageTransform imagesources={item.Banner_Image[0].url} renderer="srcSet" imagename="property.images.similarpropertytile" attr={{ alt: item.display_address + ' - Orlando Reid' }}
                                        imagetransformresult={processedImages} id={item.id} testparam={true} />
                                }
                            </figure>
                            <div className="property-title">
                                <h3>{item.display_address}</h3>
                                <div className="info price-info">
                                    <span className='title'>Price From:</span>
                                    <span className='price'>£ {item.price.toLocaleString()}</span>
                                </div>
                                <div className='feature'>
                                {item.building &&
                                    <div className="info type-info">
                                        <span className='title'>Property type</span>
                                        <span>{item.building}</span>
                                    </div>
                                }
                                <div className="info location-info">
                                    <span className='title'>Location</span>
                                    <span>{item.city}</span>
                                </div>
                                <div className="info rental-info">
                                    <span className='title'>Rental Yields</span>
                                    <span>{item.rental_yields}</span>
                                </div>
                                {item.investment_highlights &&
                                    <div className="high-info">
                                        <p><strong>Investment Highlight:</strong></p>
                                        <p>{item.investment_highlights}</p>
                                    </div>
                                }
                                </div>
                             

                                <Link className="btn btn-default" to={`${checkptype}${item.slug}-${item.id}/`}>View Property</Link>
                            </div>

                        </div>
                    }
                })}


            </Slider >
        </div>


    )

}

export default ImageSlider;
