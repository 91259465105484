import React from "react";
import { Container, Navbar, Nav } from 'react-bootstrap';
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby";

/**
 * Assest
 */
import './styles/_index.scss';

const Sitemap = (props) => {
  const data = useStaticQuery(graphql`
    query {  
    allStrapiAllMenus
    {
        edges {
          node {
            Label
            URL
            Secondary_URL
            Show_In_Burger_Menu
            Button_Class
            main_parent {
              Label
              URL
            }
            sub_parent {
              Label
              URL
            }
          }
        }
      }     
    }
  `)
  const manageClick = () => {

    let clopase = document.querySelector(".collapse")  //basic-navbar-nav 
    clopase.classList.remove("show")
    document.querySelector('body').classList.remove('stop-overflow')

  }
  const showMenu = () => {
    let clopase = document.querySelector(".collapse");
    clopase.classList.add("show")
    document.querySelector('body').classList.add('stop-overflow')
  }

  return (
    <>
      <div className="content null">
      <div className="about-text landing-intro">
        <section className="section-contact team-details site-map">
          <Container>
              <ul className="navigation align-items-xl-center">
                {data.allStrapiAllMenus.edges.map(({ node }, key) => {
                  return <>
                    {node.Show_In_Burger_Menu === true && node.Button_Class == null &&
                      <li>
                        {node.Label == 'Find' &&
                          <Link to="/properties-for-sale-in-london-and-manchester/" className={`nav-link ${node.Button_Class}`}>{node.Label}</Link>
                        }
                        {node.Label != 'Find' && node.Secondary_URL != null &&
                          <Link className="nav-link" to={`${node.Secondary_URL}/`}>{node.Label}</Link>
                        }
                        {node.Label != 'Find' && node.Secondary_URL == null &&

                          <Link to={`/${node.URL}/`} className={`nav-link ${node.Button_Class}`}>{node.Label}</Link>
                        }
                        {node.Label != "Marketing your Property" &&
                          <ul className="sub-nav">
                            {node.Label == 'Find' &&
                              <>
                                <li><Link to="/properties-for-sale-in-london-and-manchester/">Property for sale</Link></li>
                                <li><Link to="/property/to-rent/in-london-and-manchester/">Property to rent</Link></li>
                              </>
                            }
                            {data.allStrapiAllMenus.edges.map((submenu, key2) => {
                              return <>

                                {submenu.node.sub_parent == null && submenu.node.main_parent && submenu.node.main_parent.Label === node.Label && submenu.node.Secondary_URL != null &&
                                  <li><Link to={`${submenu.node.Secondary_URL}/`}>{submenu.node.Label}</Link></li>
                                }
                                {submenu.node.sub_parent == null && submenu.node.main_parent && submenu.node.main_parent.Label === node.Label && submenu.node.Secondary_URL == null &&
                                  <li><Link to={`/${node.URL}/${submenu.node.URL}/`}>{submenu.node.Label}</Link></li>
                                }
                              </>
                            })}
                          </ul>
                        }
                      </li>
                    }
                  </>
                })}
              </ul>
          </Container>
        </section>
        </div>
      </div>

    </>
  )
}

export default Sitemap
