import React, { useState, useEffect } from "react";
import { useMatch } from "@reach/router"
import { Link } from "gatsby";
import { Button, Container, Row, Col, Form } from 'react-bootstrap';
import Gallery1 from "../images/gallery1.jpg";
import Fade from 'react-reveal/Fade';
import "animate.css/animate.css";
import Girl from "../images/girl.jpg";
import ReactMarkdown from "react-markdown/with-html"
import PlayButton from './PlayButton/playButton'
import Vimeo from '@u-wave/react-vimeo';
import YouTube from '@u-wave/react-youtube';
import getVideoId from 'get-video-id';
import { VideoPlayButton } from '../components/icon';
// import ScrollAnimation from 'react-animate-on-scroll';

const ContactSection = (props)=>{
  const videoid = getVideoId(props.getInTouch.Team_Box_Video_URL);
  const detailmatch = useMatch("/about-us/our-people/:item/")
	const [animation,setAnimation] = useState(false)
  const [showVideo, setShowVideo] = React.useState(false);
  const [isPlay,setPlay] = React.useState(false);
  const trackerVideo = (event) => {    
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'Video Play Btn',
      'formType': event,
      'formId': 'Video Play',
      'formName': 'Video',
      'formLabel': 'Video'
    });
}
  if(detailmatch == null) {
	return (<React.Fragment>
      <section className="section-contact ">
        <div className="contact-img contact-imgx animate__animated">
        {/* <ScrollAnimation animateIn="animate__fadeInLeft" animateOnce={true} > */}

                <div className="vedio-card playing-video">

          <img src={props.getInTouch.Team_Box_Image.childImageSharp.fluid.src} alt="img" />
          {props.getInTouch.Team_Box_Video_URL !=null &&
          <button className="btn-play"
            onClick = { e => {setPlay(true);setShowVideo(true)}}
          >
            <VideoPlayButton />
          </button>
          }
        </div>
        {
              showVideo &&
                <YouTube
                video={videoid.id}
                autoplay
                onEnd={e => {setPlay(false);setShowVideo(false)}}
                modestBranding={1}
                onPlaying={trackerVideo('INTERESTED IN JOINING THE TEAM?')}
              />
            }
        {/* </ScrollAnimation> */}
        </div>

        <Container>
          <Row className="justify-content-xl-end">
            <Col className="col-xl-6 textContact animate__animated ">
            {/* <ScrollAnimation animateIn="animate__fadeInRight" animateOnce={true} > */}

              <div className="contact-text">                
                <ReactMarkdown source={props.getInTouch.Team_Box_Content} escapeHtml={false}/>
              </div>
              {/* </ScrollAnimation> */}
            </Col>
          </Row>
        </Container>
      </section>
      
	</React.Fragment>)
} else {
    return (
    <empty></empty>
    )
}

}

export default ContactSection;