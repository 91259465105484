import React, { useState } from "react";
import { Container, Col, Row } from 'react-bootstrap';
import { Link } from "gatsby";
import Breadcrumbs from "../components/breadcrumbs";
import { useQuery } from '@apollo/client';
import gql from "graphql-tag"
import ReactMarkdown from "react-markdown/with-html"
import Modal from 'react-bootstrap/Modal'
import MemberForm from "../components/forms/member-form"
import YouTube from '@u-wave/react-youtube';
import getVideoId from 'get-video-id';
import { VideoPlayButton, Logo } from '../components/icon';
import ImageTransform from "../components/common/ggfx-client/module/components/image-transform";
import SEO from "../components/Seo/seo";


const SINGLE_TEAM =  gql`
  query GetNews ($URL: String!){
  teams (where:{URL: $URL}){
    id
    Name
    Meta_Description
    Meta_Title
    Designation
    Phone
    Calendly_Link
    Email
    office {
      Office_Name
    }
    Staff_Image {
      url
    }
    Video_Background_Image {
      url
    }
    Embed_Video_URL
    Bio
    imagetransforms
      }
  }
`;


function TeamDetail(props) {
    const [show, setShow] = useState(false);
    const [showVideo, setShowVideo] = React.useState(false);
    const [,setPlay] = React.useState(false);
    const trackerVideo = (event) => {    
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'Video Play Btn',
        'formType': event,
        'formId': 'Video Play',
        'formName': 'Video',
        'formLabel': 'Video'
      });
  }
	  const { loading:singleteamloading, data:singleteam } = useQuery(SINGLE_TEAM, {
    variables: { URL: props.detailpage }
  });
    if (singleteamloading) return (
      <div className="static-details gql-loading-screen">
          <Container>
          <Logo />
          <div className="gql-loading">Loading ...</div>
          </Container>
      </div>);
      
    return (
      <>
      
      {singleteam.teams.map((item) => {  
            var videoid = item.Embed_Video_URL && getVideoId(item.Embed_Video_URL);
            let processedImages = JSON.stringify({});
            let metatitle = ""+item.Name+" | Property Experts"
            let metadesc = "Meet "+item.Name+" here. Orlando Reid an leading estate agents in London contact us for more information on all your property needs."
            if (item?.imagetransforms?.Staff_Image_Transforms) {
              processedImages = item.imagetransforms.Staff_Image_Transforms;
             }
          return<>
           <SEO title={metatitle} description={metadesc} />

<section className="section-contact team-details">
        <div className="contact-img team_detail_contact_img contact-imgx animate__animated">

  <div className="video-play">
          {

              showVideo &&
              
                <YouTube
  video={videoid.id}
  autoplay
  showRelatedVideos={0}
  onEnd={() => {setPlay(false);setShowVideo(false)}}
  modestBranding={1}
  onPlaying={trackerVideo(item.Name)}

/>
           
            }

          </div>
        <ImageTransform imagesources={item.Staff_Image.url} renderer="srcSet" imagename="teams.Staff_Image.teamdetail"attr={{ alt: item.Name+' '+item.Designation+ ' - Orlando Reid' }}
					imagetransformresult={processedImages} id={item.id} testparam={true} />

          {item.Embed_Video_URL !=null && item.Embed_Video_URL != '' &&
          <button className="btn-play" onClick = { () => {setPlay(true);setShowVideo(true)}}>
            <VideoPlayButton />
          </button>
          }
        </div>
        <Container>
          <Row className="justify-content-xl-end">
            <Col className="col-xl-6 textContact scrollbar animate__animated ">
            <Breadcrumbs alias='our-people' detailname={item.Name} />

              <div className="contact-text team-info">                
                <h2>{item.Name}</h2>
                <span className="staff-position">{item.Designation}</span>
                {item.Calendly_Link && item.Calendly_Link !=="" &&
                <div className="btn-wrap d-none d-xl-block">
                  <Link className="btn btn-success">book a video call</Link>
                </div>
                }
              <div className="team-contact">
                {item.Phone &&
                <>
                <a href={`tel:${item.Phone}`}>{item.Phone}</a><span> / </span>
                </>
                }
                <a href="javascript:;" onClick={() => setShow(true)}>Email</a>
                </div>
 <Modal
        show={show}
        centered={true}
        onHide={() => setShow(false)}
        dialogClassName="modal-90w modal-form"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            CONTACT - {item.Name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <MemberForm to_email_id={item.Email} formName={`Contact Person`} office={item?.office?.Office_Name} />
        </Modal.Body>
      </Modal>

                <ReactMarkdown source={item.Bio} escapeHtml={false}/>
              </div>
            </Col>
          </Row>
        </Container>
      </section>     
          </>
  })}
      </>
      )
}
export default TeamDetail

