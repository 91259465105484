import React, { useState } from "react";
import gql from "graphql-tag"
import { useQuery } from '@apollo/client';
import { Container } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal'
import BookingForm from "./forms/book-a-view-form";
import SalesForceForm from "./forms/salesforce-form"
import { Logo } from '../components/icon';

const SINGLE_OFFICE = gql`
query GetTeamDetails ($Office_CRM_ID: String!){
  offices (where:{Office_CRM_ID:$Office_CRM_ID}) {
    Title
    URL
    Phone_Number
    Office_CRM_ID
    Email
  }
  defaultoffice: offices (where:{Title:"Clapham Office"}) {
    Title
    URL
    Phone_Number
    Office_CRM_ID
    Email
  }
}
`;


function TeamDetails(props) { 
  const [show, setShow] = useState(false);
  let office_crm_mapping = {"5":"ORM"}
  let office_crm_id = office_crm_mapping[props.officecrmid] || props.officecrmid
  const { loading, error, data } = useQuery(SINGLE_OFFICE, {
    variables: { Office_CRM_ID: office_crm_id }
  });
  const offices = data?.offices?.length !== 0 ? data?.offices : data?.defaultoffice;
  const officecrmid = data?.offices?.length !== 0 ? props?.officecrmid : data?.defaultoffice[0]?.Office_CRM_ID;
  if (loading) return (
    <div className="static-details gql-loading-screen">
        <Container>
        <Logo />
        <div className="gql-loading">Loading ...</div>
        </Container>
    </div>);

  return (
    <>
      {offices?.map((node, key) => {
        if(key == 0) {
        return <>
        
    <a href="javascript:;" className="btn btn-success triggerbooking" onClick={() => setShow(true)}>book a viewing</a>
	<Modal
        show={show}
        centered={true}
        onHide={() => setShow(false)}
        dialogClassName="modal-90w modal-form"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Book a Viewing
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {(props.city.toLowerCase() == "manchester"  || props.city.toLowerCase() == "manchster" || props.propertyurl.includes("manchester") || props.propertyurl.includes("manchster")) && props.searchType=="sales" ?
           <SalesForceForm className="full-width" propertyName={props.displayAddress} 
           retURL={process.env.GATSBY_SITE_URL+"/thank-you-book-a-viewing-sales"} /> 
           :
           <BookingForm office={officecrmid} propertyurl = {props.propertyurl} 
          to_email_id={
          props.propertyurl.includes('property-to-rent') && props.city.toLowerCase() === "manchester"
            ? "ManchesterLettings@orlandoreid.co.uk" 
            : node.Email
}           
            city ={props.city}
           />
        }       
       
        </Modal.Body>
      </Modal>
			      
        </>
      }
      })}
    </>
  )
}

export default TeamDetails