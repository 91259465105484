import React from "react";
import { Link } from "gatsby"

import SEO from "../components/Seo/seo";
import Header from "../components/Header2/header2";
import Footer from "../components/Footer/footer";

const NotFoundPage = () => (
  <>
    <Header />

    <div className="content null">
      <div className="about-text landing-intro error-full-bg">
        <SEO title="404: Not found" />
        <div className="not-found-page">
          <h1>Sorry, the page you're after seems to be missing.</h1>
          <p>This is maybe because it has been removed, we've changed its name or it is temporarily unavailable.</p>
          <Link to="/" className="primary-button btn btn-success">Home Page</Link>
          <Link to="/contact/our-offices/" className="primary-button btn btn-default">Contact Us</Link>
        </div>
      </div>
    </div>

    {/* <Footer /> */}
  </>
)

export default NotFoundPage
